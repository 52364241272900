import React, { useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from 'antd';
import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';
import './jobs.css';
import Jobs from './Jobs';
import JobDetails from './JobDetails';
import { useNavigate } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ConversationContext from '../../Context/conversation/ConversationContext';

const Main = () => {
  const navigate = useNavigate();
  const context = useContext(ConversationContext);

  const { credits } = context;

  useEffect(() => {
    if (!localStorage.token) navigate('/login');
  });

  useEffect(() => {
    // eslint-disable-next-line
  }, [credits]);

  return (
    <React.Fragment>
      <Grid container>
        <Component>
          <Paper elevation={0}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#fff',
                borderBottom: '1px solid #DADDE1',
                height: '54px',
              }}>
              <Grid
                item
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => navigate(`/`)}>
                <img
                  width={120}
                  style={{ float: 'left' }}
                  src={require('../../assets/images/logo2.png')}
                  alt='logo'
                />
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '10px',
                }}>
                <Tooltip title='Credits Balance'>
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #b32800',
                      borderRadius: '16px',
                      padding: '2px 8px',
                      marginTop: '0px',
                      marginRight: '5px',
                    }}>
                    <AccountBalanceWalletIcon
                      style={{
                        background: 'none',
                        color: '#b32800',
                        marginRight: '4px',
                      }}
                    />{' '}
                    {credits}
                  </span>
                </Tooltip>
                <Tooltip title='Logout'>
                  <IconButton
                    variant='contained'
                    size='small'
                    style={{
                      padding: '3px 10px',
                      background: 'none',
                      color: '#b32800',
                    }}
                    onClick={() => {
                      localStorage.clear();
                      navigate('/login');
                    }}>
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </div>
          </Paper>

          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              margin: '0px 0 0 2px',
              padding: '8px',
              background: '#edfaf8',
              borderBottom: '1px solid #DADDE1',
            }}>
            <Button className='active-button' onClick={() => navigate('/jobs')}>
              Jobs
            </Button>
            <Button
              onClick={() => navigate('/')}
              style={{ marginLeft: '8px' }}
              className='inactive-button'>
              Chats
            </Button>
            <Button
              className='inactive-button-use'
              style={{ marginLeft: '8px' }}
              onClick={() => navigate('/usage')}>
              How To Use
            </Button>
          </div>

          <div>
            <Jobs />
          </div>
        </Component>
        <JobDetails />
      </Grid>
    </React.Fragment>
  );
};

const Component = styled(Box)`
  overflow: overlay;
  height: 99vh;
  width: 360px;
  border-right: 1px solid #dadde1;
`;

export default Main;
