import React, { useState, useEffect, useContext } from 'react';
import * as XLSX from 'xlsx';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import JobContext from '../../Context/job/JobContext';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { CircularProgress } from '@mui/material';
import { message } from 'antd';
import ConversationContext from '../../Context/conversation/ConversationContext';

const UploadExcel = (props) => {
  const [open, setOpen] = useState(props.open);
  const [loading, setLoading] = useState(false);
  const { data } = props;
  const context = useContext(JobContext);
  const context3 = useContext(ConversationContext);

  const {
    team_members,
    uploadCandidate,
    upload_success,
    stateReset,
    upload_message,
  } = context;

  const { getCredits, credits } = context3;

  const [state, setState] = useState({
    data: [],
    job: {},
    email: localStorage.email,
    assigned_to: '',
  });

  const [temp, setTemp] = useState({
    allot_to: '',
  });

  useEffect(() => {
    if (upload_success) {
      setOpen(false);
      stateReset({ upload_success: false, upload_message: '' });
      setLoading(false);
      props.reset();
      getCredits();
      setState({
        data: [],
        job: {},
        email: localStorage.email,
        assigned_to: '',
      });
      setTemp({
        allot_to: '',
      });
      message.success(upload_message);
    }
    // eslint-disable-next-line
  }, [upload_success]);

  useEffect(() => {
    if (data) {
      setState({
        ...state,
        job: data,
      });
    }
    // eslint-disable-next-line
  }, [props]);

  const readUploadFile = (e) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        console.log(json);
        setState({
          ...state,
          data: json,
        });
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    uploadCandidate(state);
    setLoading(true);
  };

  return (
    <>
      {/* <Tooltip title={data?.approved ? 'Upload Excel' : 'In review'}>
        <CloudUploadOutlined
          onClick={() => {
            setOpen(true);
          }}
        />
      </Tooltip> */}
      <Dialog
        open={open}
        onClose={() => {
          props.reset();
          setOpen(false);
        }}>
        <DialogTitle>Import Candidates Excel</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <input
              type='file'
              name='upload'
              id='upload'
              required
              accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              onChange={readUploadFile}
            />

            <p></p>
            <FormControl size='small' fullWidth>
              <InputLabel id='allot_to'>Allot To</InputLabel>
              <Select
                labelId='allot_to'
                value={temp.allot_to}
                label='Allot To'
                fullWidth
                onChange={(e) => {
                  setTemp({
                    ...temp,
                    allot_to: e.target.value,
                  });

                  setState({
                    ...state,
                    assigned_to: e.target.value,
                  });
                }}>
                {team_members
                  ? team_members.map((item) => (
                      <MenuItem key={item._id} value={item.email}>
                        {item.email}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>

            {data?.approved ? (
              <i style={{ fontSize: '11px' }}>
                Note: Please use format - Column A (Name) and Column B (Phone)
              </i>
            ) : (
              <i style={{ fontSize: '11px' }}>
                Note: This job is not approved by admin yet. Please try again
                later.
              </i>
            )}

            {localStorage.plan_type !== 'Pro' &&
            localStorage.plan_type !== 'FreePro' ? (
              <p>Please upgrade your plan to use this feature.</p>
            ) : null}

            {state?.data?.length > credits ? (
              <p>Your excel data is more than your wallet credits...</p>
            ) : null}

            {loading ? (
              <p>
                <CircularProgress /> <br /> Hang tight we're sending messages
                for you...{' '}
              </p>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                props.reset();
                setOpen(false);
              }}>
              Cancel
            </Button>
            <Button
              disabled={
                (localStorage.plan_type !== 'Pro' &&
                  localStorage.plan_type !== 'FreePro') ||
                !data?.approved ||
                state?.data?.length > credits ||
                state?.data?.length === 0
              }
              type='submit'
              autoFocus>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UploadExcel;
