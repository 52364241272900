export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_CONVERSATION = 'GET_CONVERSATION';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const GET_MESSAGES = 'GET_MESSAGES';
export const MESSAGE_ERROR = 'MESSAGE_ERROR';
export const CONVERSATION_ERROR = 'CONVERSATION_ERROR';
export const STATE_RESET = 'STATE_RESET';
export const CANDIDATE_INFO = 'CANDIDATE_INFO';
export const ADD_STATUS = 'ADD_STATUS';
export const SEND_TEMPLATE_SUCCESS = 'SEND_TEMPLATE_SUCCESS';
export const SEND_TEMPLATE_ERROR = 'SEND_TEMPLATE_ERROR';
export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_AS_UNREAD = 'MARK_AS_UNREAD';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_CREDITS = 'GET_CREDITS';

// for Jobs
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
export const GET_JOBS_FAILURE = 'GET_JOBS_FAILURE';
export const GET_JOB_CANDIDATES_SUCCESS = 'GET_JOB_CANDIDATES_SUCCESS';
export const GET_JOB_CANDIDATES_FAILURE = 'GET_JOB_CANDIDATES_FAILURE';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';
export const CREATE_JOB_FAILURE = 'CREATE_JOB_FAILURE';
export const UPDATE_JOB_FAILURE = 'UPDATE_JOB_FAILURE';
export const DELETE_JOB_FAILURE = 'DELETE_JOB_FAILURE';
export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const GET_TEAM_FAILURE = 'GET_TEAM_FAILURE';

// for templates
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_FAILURE = 'GET_TEMPLATE_FAILURE';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_FAILURE = 'CREATE_TEMPLATE_FAILURE';
export const UPDATE_TEMPLATE_FAILURE = 'UPDATE_TEMPLATE_FAILURE';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';
