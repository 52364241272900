import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import JobContext from '../../Context/job/JobContext';
import { useParams, useNavigate } from 'react-router-dom';
import { Table, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const JobDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mobile',
      dataIndex: 'phone',
      key: 'phone',
      render: (text) => text.toString().substr(2),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: 'Chat',
      key: '_id',
      render: (item) => (
        <Button
          disabled={!item.conversation_initiated}
          onClick={() => navigate(`/chat/${item._id}`)}>
          Chat
        </Button>
      ),
    },
  ];

  const context = useContext(JobContext);

  const {
    candidate_success,
    candidates,
    getCandidates,
    create_success,
    stateReset,
  } = context;

  const [loading, setLoading] = useState(true);
  const [sear, setSearch] = useState('');
  const [arrow, setArrow] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getCandidates(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (candidate_success) {
      stateReset({ candidate_success: false });
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [candidate_success]);

  useEffect(() => {
    if (create_success) {
      setArrow(true);
    }
    // eslint-disable-next-line
  }, [create_success]);

  useEffect(() => {
    if (candidates && window.location.href.includes('jobs/')) {
      setLoading(false);
      setArrow(false);
    }
    // eslint-disable-next-line
  }, [candidates]);

  if (!window.location.href.includes('jobs/'))
    return (
      //   <Grid item xs={false} style={{ width: '50%' }} md={7} className='image' />
      <>
        {arrow ? (
          <div
            style={{
              width: `calc(100% - 361px)`,
            }}>
            <div className='graph__wrapper'>
              <svg
                width='315px'
                height='107px'
                viewBox='0 0 315 107'
                version='1.1'
                style={{ overflow: 'visible' }}>
                <g
                  id='Page-1'
                  stroke='none'
                  strokeWidth='1'
                  fill='none'
                  fillRule='evenodd'>
                  <path
                    id='Path-1'
                    className='path'
                    fill='none'
                    stroke='#db5862'
                    strokeWidth='5'
                    strokeLinejoin='round'
                    strokeMiterlimit='10'
                    d='M1.4,2.1c0,0,86,57,211.5,41.5s172.5-24.5,289,81'
                  />

                  <path
                    className='dashed'
                    fill='none'
                    stroke='white'
                    strokeWidth='8'
                    strokeLinejoin='round'
                    strokeMiterlimit='10'
                    d='M1.4,2.1c0,0,86,57,211.5,41.5s172.5-24.5,289,81'
                  />

                  <polyline
                    id='arrow'
                    points='0,-9 18,0 0,9 5,0'
                    fill='#db5862'>
                    <animateMotion
                      rotate='auto'
                      begin='1s'
                      dur='1.6s'
                      repeatCount='1'
                      fill='freeze'>
                      <mpath href='#Path-1' />
                    </animateMotion>
                  </polyline>
                </g>
              </svg>
            </div>
            <div className='div-down'>
              Download Plugin to add resume from Naukri
              <Button
                target='_blank'
                href='https://chrome.google.com/webstore/detail/monkbot/jghlncenolgagmjjnjnmiomkoglogedi?hl=en&authuser=0'
                className='ifrm'>
                Download Plugin
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              padding: '16px',
              width: '100%',
              height: '60px',
            }}>
            <Button
              target='_blank'
              href='https://chrome.google.com/webstore/detail/monkbot/jghlncenolgagmjjnjnmiomkoglogedi?hl=en&authuser=0'
              className='ifrm'>
              Download Plugin
            </Button>
          </div>
        )}
      </>
    );

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        style={{
          width: `calc(100% - 361px)`,
          display: 'flex',
          alignItems: 'center',
          background: '#edfaf8',
        }}>
        <Grid item id='JobDetails'>
          <div
            style={{
              width: '80%',
            }}>
            <Input
              placeholder='Search for Name / Mobile / Status'
              allowClear
              prefix={<SearchOutlined />}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Table
              loading={loading}
              size='small'
              columns={columns}
              dataSource={candidates.filter(
                (item) =>
                  item.status.toLowerCase().includes(sear.toLowerCase()) ||
                  item.name.toLowerCase().includes(sear.toLowerCase()) ||
                  item.phone.toString().includes(sear.toLowerCase())
              )}
              rowKey={'_id'}
              scroll={{
                y: '70vh',
              }}
            />
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default JobDetails;
