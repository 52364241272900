import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ConversationContext from '../../../Context/conversation/ConversationContext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const LateReply = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('en');

  const { phone, id, closeIt } = props;
  const context = useContext(ConversationContext);

  const { sendTemplate } = context;

  const handleSubmit = async () => {
    let obj = {
      id: id,
      phone: phone,
      template_name: 'late_reply',
      language_code: value,
      component: null,
      email: localStorage.email,
    };
    if (value === 'en')
      obj.text =
        'Hey, Kindly accept our sincere apologies for the delay in reply. Our team will assist you.';
    else if (value === 'en_US')
      obj.text = 'Hey, We apologize for not getting back to you sooner.';
    else if (value === 'en_GB')
      obj.text = 'Hey, We apologize for the holdup in the proceedings.';

    sendTemplate(obj);
    setValue();
    setOpen(false);
    closeIt();
  };

  return (
    <>
      <Button
        variant='standard'
        style={{ textTransform: 'capitalize' }}
        onClick={() => {
          setOpen(true);
        }}>
        <WatchLaterIcon fontSize='medium' />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Choose a Late Reply Template</DialogTitle>
        <DialogContent>
          {phone ? (
            <RadioGroup
              value={value}
              onChange={(e) => setValue(e.target.value)}>
              <FormControlLabel
                value='en'
                control={<Radio />}
                label='Hey, Kindly accept our sincere apologies for the delay in reply. Our team will assist you.'
              />
              <br />
              <FormControlLabel
                value='en_US'
                control={<Radio />}
                label='Hey, We apologize for not getting back to you sooner.'
              />
              <br />
              <FormControlLabel
                value='en_GB'
                control={<Radio />}
                label='Hey, We apologize for the holdup in the proceedings.'
              />
            </RadioGroup>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            variant='standard'
            style={{ textTransform: 'capitalize' }}
            onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button
            variant='contained'
            style={{ textTransform: 'capitalize' }}
            onClick={handleSubmit}
            autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LateReply;
