import React from 'react';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const MessageList = ({ details }) => {
  const navigate = useNavigate();

  return (
    <div
      className='candidate-list-div'
      onClick={() => navigate(`/chat/${details?._id}`)}>
      <div>
        <Image sx={{ bgcolor: '#fff' }} alt={details?.name}>
          {details?.name.charAt(0)}
        </Image>
      </div>
      <div style={{ width: '100%' }}>
        <div className='candidate-container'>
          <p className='candidate-container-name'>
            {details?.name.includes(' ')
              ? details?.name.split(' ')[0] + ' ' + details?.name.split(' ')[1]
              : details?.name}
          </p>
          <p className='candidate-timestamp'>
            {details?.latest_message === null
              ? ''
              : new Date(details?.latest_message?.timestamp * 1000)
                  .toDateString()
                  .split(' ')[2] +
                ' ' +
                new Date(details?.latest_message?.timestamp * 1000)
                  .toDateString()
                  .split(' ')[1] +
                ' '}
            {new Date(
              details?.latest_message?.timestamp * 1000
            ).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </p>
        </div>
        <div>
          {details?.latest_message === null ? (
            ''
          ) : details?.latest_message?.status === 'read' ? (
            <p className='candidate-textread'>
              {details.latest_message.type === 'template' ||
              details.latest_message.type === 'text'
                ? details.latest_message.body.body
                : details.latest_message.type === 'reaction'
                ? details.latest_message.body.emoji
                : details.latest_message.type === 'button'
                ? details.latest_message.body.text
                : 'Media'}
            </p>
          ) : (
            <div
              style={{
                display: 'flex',
                paddingRight: '20px',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <p className='candidate-textunread'>
                {details.latest_message.type === 'template' ||
                details.latest_message.type === 'text'
                  ? details.latest_message.body.body
                  : details.latest_message.type === 'reaction'
                  ? details.latest_message.body.emoji
                  : details.latest_message.type === 'button'
                  ? details.latest_message.body.text
                  : 'Media'}
              </p>
              <p
                style={{
                  width: '10px',
                  height: '10px',
                  background: 'green',
                  borderRadius: '50%',
                  margin: '0px',
                }}></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MessageList.protoTypes = {
  details: PropTypes.object.isRequired,
};

const Image = styled(Avatar)`
  margin: 5px 10px 10px 10px;
  height: 35px;
  color: #b32800;
  border: 1px solid #b32800;
  width: 35px;
`;

export default MessageList;
