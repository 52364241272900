import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Table } from 'antd';
import Grid from '@mui/material/Grid';
import ApprovalForm from './ApprovalForm';

const AllJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [info, setInfo] = useState();
  const [open, setOpen] = useState(false);

  async function fetchJobs() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.get(
        `https://monk-bot.azurewebsites.net/admin/user-jobs?admin=${localStorage.email}`,
        config
      );

      if (res) {
        setJobs(res.data.data);
        setOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, [jobs]);

  const handleClick = (val) => {
    setInfo(val);
  };

  const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Approved',
      key: 'approved',
      render: (item) => (item.approved ? 'Approved' : 'Not Approved'),
    },
    {
      title: 'See Templates',
      key: '_id',
      render: (item) => (
        <Button
          onClick={() => {
            handleClick(item);
            setOpen(true);
          }}>
          Job Details
        </Button>
      ),
    },
  ];

  return (
    <>
      {open ? (
        <ApprovalForm
          openIt={open}
          info={info}
          back={fetchJobs}
          reset={() => setOpen(false)}
        />
      ) : null}
      <Grid
        container
        spacing={0}
        style={{
          display: 'flex',
          alignItems: 'center',
          background: '#edfaf8',
          height: '99vh',
        }}>
        <Grid item id='JobDetails'>
          <div
            style={{
              width: '80%',
            }}>
            <Table
              size='small'
              columns={columns}
              dataSource={jobs}
              rowKey={'_id'}
              scroll={{
                y: '70vh',
              }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AllJobs;
