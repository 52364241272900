// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCAUK7c-KNMIsYHV9tr9cUFtF7w68s362w',
  authDomain: 'ta-coaching.firebaseapp.com',
  databaseURL: 'https://ta-coaching.firebaseio.com',
  projectId: 'ta-coaching',
  storageBucket: 'ta-coaching.appspot.com',
  messagingSenderId: '503126540750',
  appId: '1:503126540750:web:e1a1496b1f70bb89ec2b9f',
  measurementId: 'G-W39SZ771F1',
};

// Initialize Firebase
export const fire = initializeApp(firebaseConfig);
export const database = getDatabase();

export default fire;
