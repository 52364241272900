import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { DeleteFilled } from '@ant-design/icons';
import { Card, message, Skeleton, Tag, Tooltip } from 'antd';
import JobContext from '../../Context/job/JobContext';
import CreateJob from './CreateJob';
import EditJob from './EditJob';
import UploadExcel from './UploadExcel';
import { useNavigate, useParams } from 'react-router-dom';
import { CloudUploadOutlined, EditFilled } from '@ant-design/icons';
import ConversationContext from '../../Context/conversation/ConversationContext';

const { Meta } = Card;

const Jobs = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const context = useContext(JobContext);
  const context5 = useContext(ConversationContext);

  const { job_error, jobs, get_job_success, deleteJob } = context;
  const { getJobs, stateReset, delete_success } = context;

  const { getCredits } = context5;

  const [loading, setLoading] = useState(false);
  const [renderUpload, setRenderUpload] = useState(false);
  const [renderEdit, setRenderEdit] = useState(false);
  const [dataItem, setDataItem] = useState();

  useEffect(() => {
    setLoading(true);
    getJobs();
    getCredits();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (job_error) {
      setLoading(false);
      message.error(job_error);
      stateReset({ job_error: null });
    }
    // eslint-disable-next-line
  }, [job_error]);

  useEffect(() => {
    if (get_job_success) {
      setLoading(false);
      stateReset({ get_job_success: false });
    }
    // eslint-disable-next-line
  }, [get_job_success]);

  useEffect(() => {
    if (delete_success) {
      stateReset({ delete_success: false });
      message.success('Job deleted');
    }
    // eslint-disable-next-line
  }, [delete_success]);

  return (
    <React.Fragment>
      {renderUpload ? (
        <UploadExcel
          reset={() => {
            setDataItem();
            setRenderUpload(false);
          }}
          open={true}
          data={dataItem}
        />
      ) : null}
      {renderEdit ? (
        <EditJob
          reset={() => {
            setDataItem();
            setRenderEdit(false);
          }}
          openIt={true}
          info={dataItem}
        />
      ) : null}
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'right', paddingRight: '12px' }}>
          <CreateJob />
          {/* <CreateTemplate /> */}
        </Grid>

        <Grid item xs={12} style={{ marginTop: '-5px', padding: '12px' }}>
          {jobs && jobs.length > 0
            ? jobs.map((item) => (
                <Card
                  key={item._id}
                  style={{
                    width: '100%',
                    marginTop: 15,
                    borderRadius: '8px',
                  }}
                  className={id === item._id ? 'active-card' : 'not-active'}
                  actions={[
                    <Tooltip
                      key={item._id}
                      title={
                        localStorage.plan_type !== 'Pro' &&
                        localStorage.plan_type !== 'FreePro'
                          ? 'Upload Excel is not available in free version'
                          : item.approved
                          ? 'Upload Excel'
                          : 'In review'
                      }>
                      <CloudUploadOutlined
                        onClick={() => {
                          setDataItem(item);
                          setRenderUpload(true);
                        }}
                      />
                    </Tooltip>,
                    <Tooltip key={item._id} title='Edit Job'>
                      <EditFilled
                        onClick={() => {
                          setDataItem(item);
                          setRenderEdit(true);
                        }}
                      />
                    </Tooltip>,
                    <Tooltip title='Delete Job'>
                      <DeleteFilled
                        key='delete'
                        onClick={() => deleteJob(item._id)}
                      />
                    </Tooltip>,
                  ]}>
                  <Skeleton loading={loading} active>
                    <Meta
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/jobs/${item._id}`)}
                      title={
                        <>
                          <span>{item.role}</span>
                          <span>
                            {localStorage.company_type === 'Agency' ? (
                              <> - {item.client}</>
                            ) : null}
                          </span>
                        </>
                      }
                      description={
                        <>
                          <p>Location: {item.location}</p>
                          <p>Created By: {item.created_by}</p>
                          {item.approved ? (
                            <Tag color='green'>Approved</Tag>
                          ) : (
                            <Tag color='warning'>In review</Tag>
                          )}
                        </>
                      }
                    />
                  </Skeleton>
                </Card>
              ))
            : 'No Jobs Yet'}
        </Grid>

        {/* <p className='title-here'>Message Templates</p>
        <Grid item xs={12} style={{ marginTop: '-5px', padding: '5px' }}>
          {templates && templates.length > 0
            ? templates.map((item) => (
                <Accordion
                  key={item._id}
                  expanded={expanded === item._id}
                  onChange={handleChange(item._id)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {item.template_name}
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>Body: {item.body}</p>
                  </AccordionDetails>
                </Accordion>
              ))
            : 'No Templates Yet'}
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
};

export default Jobs;
