import React, { useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Button as Butto,
  Divider,
  message,
  Select as AntSelect,
  Alert,
  Space,
  Radio,
} from 'antd';
import TemplateContext from '../../Context/template/TemplateContext';
import { temps, temps2 } from './PreDefinedTemplates';
import axios from 'axios';

const EditTemplates = (props) => {
  const { type, upd, state, closeForm } = props;
  const [template, setTemplate] = useState();
  const [used, setUsed] = useState(false);
  const [mcq, setMcq] = useState(true);
  const [usedName, setUsedName] = useState('');
  const [edit, setEdit] = useState(false);
  const [editCopy, setEditCopy] = useState(false);
  const [editSample, setEditSample] = useState(false);
  const [newTemplate, setNewTemplate] = useState({
    _id: '',
    template_name: '',
    template_type: '',
    body: '',
    response_buttons: '',
    created_by: localStorage.email,
  });

  const context2 = useContext(TemplateContext);

  const {
    updateTemplate,
    postTemplate,
    templates,
    getTemplates,
    update_template_success,
    create_template_success,
    stateReset,
    template_error,
  } = context2;

  async function checkTemplate() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.get(
        `https://monk-bot.azurewebsites.net/jobs/template-used-search?email=${localStorage.email}&template_name=${newTemplate.template_name}`,
        config
      );

      if (res) {
        setUsed(true);
        setUsedName(res.data.data);
      }
      // setEdit(true);
    } catch (err) {
      setEdit(true);
      // parentEditing();
      setUsed(false);
    }
  }

  const updateParentState = async (val) => {
    await getTemplates();
    if (type === 'Initial Message') {
      upd({
        ...state,
        first_template: templates.filter((info) => info._id === val)[0]
          .template_name,
      });
    } else if (type === 'Know More') {
      upd({
        ...state,
        second_template: templates.filter((info) => info._id === val)[0]
          .template_name,
      });
    } else if (type === 'Question 1') {
      upd({
        ...state,
        third_template: templates.filter((info) => info._id === val)[0]
          .template_name,
      });
    } else if (type === 'Question 2') {
      upd({
        ...state,
        fourth_template: templates.filter((info) => info._id === val)[0]
          .template_name,
      });
    } else if (type === 'Question 3') {
      upd({
        ...state,
        fifth_template: templates.filter((info) => info._id === val)[0]
          .template_name,
      });
    } else if (type === 'Question 4') {
      upd({
        ...state,
        sixth_template: templates.filter((info) => info._id === val)[0]
          .template_name,
      });
    } else if (type === 'Thank You') {
      upd({
        ...state,
        seventh_template: templates.filter((info) => info._id === val)[0]
          .template_name,
      });
    }
  };

  const newParentState = async (val) => {
    await getTemplates();
    if (type === 'Initial Message') {
      upd({
        ...state,
        first_template: templates.filter(
          (info) => info.template_name === val
        )[0].template_name,
      });
    } else if (type === 'Know More') {
      upd({
        ...state,
        second_template: templates.filter(
          (info) => info.template_name === val
        )[0].template_name,
      });
    } else if (type === 'Question 1') {
      upd({
        ...state,
        third_template: templates.filter(
          (info) => info.template_name === val
        )[0].template_name,
      });
    } else if (type === 'Question 2') {
      upd({
        ...state,
        fourth_template: templates.filter(
          (info) => info.template_name === val
        )[0].template_name,
      });
    } else if (type === 'Question 3') {
      upd({
        ...state,
        fifth_template: templates.filter(
          (info) => info.template_name === val
        )[0].template_name,
      });
    } else if (type === 'Question 4') {
      upd({
        ...state,
        sixth_template: templates.filter(
          (info) => info.template_name === val
        )[0].template_name,
      });
    } else if (type === 'Thank You') {
      upd({
        ...state,
        seventh_template: templates.filter(
          (info) => info.template_name === val
        )[0].template_name,
      });
    }
  };

  useEffect(() => {
    if (update_template_success) {
      message.success('Template Updated');
      // getTemplates();
      stateReset({ update_template_success: false });
      updateParentState(template);
      setTemplate();
      setNewTemplate({
        _id: '',
        template_name: '',
        template_type: '',
        body: '',
        response_buttons: '',
        created_by: localStorage.email,
      });
      setEdit(false);
      closeForm();
      // parentNotEditing();
    }
    // eslint-disable-next-line
  }, [update_template_success]);

  useEffect(() => {
    if (create_template_success) {
      // getTemplates();
      message.success('Template Added');
      stateReset({ create_template_success: false });
      newParentState(newTemplate.template_name);
      setTemplate();
      setEditSample(false);
      setNewTemplate({
        template_name: '',
        template_type: '',
        body: '',
        response_buttons: '',
        created_by: localStorage.email,
      });
      closeForm();
    }
    // eslint-disable-next-line
  }, [create_template_success]);

  useEffect(() => {
    if (template_error) {
      message.error(template_error);
      stateReset({ template_error: null });
    }
    // eslint-disable-next-line
  }, [template_error]);

  useEffect(() => {
    if (create_template_success && editCopy) {
      message.success('Template Added');
      // getTemplates();
      stateReset({ create_template_success: false });
      newParentState(newTemplate.template_name);
      setTemplate();
      setNewTemplate({
        _id: '',
        template_name: '',
        template_type: '',
        body: '',
        response_buttons: '',
        created_by: localStorage.email,
      });
      setEdit(false);
      setEditCopy(false);
      closeForm();
      // parentNotEditing();
    }
    // eslint-disable-next-line
  }, [create_template_success]);

  const handleTemplate = async (val) => {
    setEdit(false);
    setUsed(false);
    setEditSample(false);
    setTemplate(val);

    if (val === 'Create New') {
      setEditSample(true);
      setNewTemplate({
        template_name: '',
        template_type: type,
        body: '',
        response_buttons: '',
        created_by: localStorage.email,
      });
    }

    if (/\d/.test(val)) {
      setNewTemplate({
        _id: val,
        template_name: templates.filter((info) => info._id === val)[0]
          .template_name,
        template_type: templates.filter((info) => info._id === val)[0]
          .template_type,
        body: templates.filter((info) => info._id === val)[0].body,
        response_buttons: templates
          .filter((info) => info._id === val)[0]
          .response_buttons.join(','),
        created_by: localStorage.email,
      });

      updateParentState(val);
    } else {
      setEditSample(true);
      setNewTemplate({
        template_name: '',
        template_type: temps2.filter((info) => info.template_name === val)[0]
          .template_type,
        body: temps2.filter((info) => info.template_name === val)[0].body,
        response_buttons: temps2
          .filter((info) => info.template_name === val)[0]
          .response_buttons.join(','),
        created_by: localStorage.email,
      });
    }
  };

  const handleEdit = async () => {
    await checkTemplate();
    // call api here to check if template is already used in a job
  };

  const handleEditCopy = async () => {
    setUsed(false);
    setNewTemplate({
      ...newTemplate,
      template_name: newTemplate.template_name + ' Copy',
    });
    setEditCopy(true);
    setEdit(true);
  };

  const addCopyTemplate = async () => {
    if (newTemplate.template_name === '' || newTemplate.body === '')
      return message.error('Template name or body cannot be empty');

    if (newTemplate.response_buttons.split(',').length > 3)
      return message.error('You are only allowed to add upto 3 option buttons');

    postTemplate({
      template_name: newTemplate.template_name,
      template_type: newTemplate.template_type,
      body: newTemplate.body,
      response_buttons: newTemplate.response_buttons.split(','),
      created_by: localStorage.email,
    });
  };

  const updateNewTemplate = async () => {
    if (newTemplate.template_name === '' || newTemplate.body === '')
      return message.error('Template name or body cannot be empty');

    if (newTemplate.response_buttons.split(',').length > 3)
      return message.error('You are only allowed to add upto 3 option buttons');

    updateTemplate({
      _id: newTemplate._id,
      template_name: newTemplate.template_name,
      template_type: newTemplate.template_type,
      body: newTemplate.body,
      response_buttons: newTemplate.response_buttons.split(','),
      created_by: localStorage.email,
    });
  };

  const addNewTemplate = async () => {
    if (newTemplate.template_name === '' || newTemplate.body === '')
      return message.error('Template name or body cannot be empty');

    if (newTemplate.response_buttons.split(',').length > 3)
      return message.error('You are only allowed to add upto 3 option buttons');

    postTemplate({
      template_name: newTemplate.template_name,
      template_type: newTemplate.template_type,
      body: newTemplate.body,
      response_buttons: newTemplate.response_buttons.split(','),
      created_by: localStorage.email,
    });
  };

  useEffect(() => {
    // console.log(props);
    if (type) {
      setTemplate();
    }
  }, [type]);

  return (
    <div>
      {used ? (
        <Space
          direction='vertical'
          style={{
            width: '100%',
            marginBottom: '10px',
          }}>
          <Alert
            closable
            onClose={() => {
              setUsed(false);
              setEdit(false);
              // parentNotEditing();
            }}
            message={
              <span>
                Alert !! Create new template, as this one is already in use for{' '}
                {usedName}.
              </span>
            }
            description={
              <span> Editing this will impact {usedName} job as well.</span>
            }
            showIcon
            type='warning'
            action={
              <Space direction='vertical'>
                <Butto
                  size='small'
                  style={{ width: '100%' }}
                  type='primary'
                  danger
                  onClick={() => {
                    setUsed(false);
                    setEdit(true);
                    // parentEditing();
                  }}>
                  {' '}
                  Continue Editing{' '}
                </Butto>
                <Butto
                  size='small'
                  style={{ width: '100%' }}
                  onClick={handleEditCopy}>
                  Create a Copy
                </Butto>
              </Space>
            }
          />
        </Space>
      ) : null}

      {type?.includes('Question') ? (
        <>
          <Radio.Group onChange={(e) => setMcq(e.target.value)} value={mcq}>
            <Radio value={true}>MCQ</Radio>
            <Radio value={false}>Explanation</Radio>
          </Radio.Group>
          <br />
        </>
      ) : null}

      {templates && templates.length >= 0 && !type?.includes('Question') ? (
        <AntSelect
          style={{
            width: '100%',
          }}
          value={template}
          placeholder='Choose a Template To Modify'
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: '8px 0',
                }}
              />
            </>
          )}
          // options={templates.map((item) => ({
          //   label: item.template_name,
          //   value: item._id,
          // }))}
          options={[
            {
              label: (
                <a className='whatsapp-builder-button2'>
                  <i>Create New..</i>
                </a>
              ),
              value: 'Create New',
            },
            {
              label: 'Sample Templates',
              options: temps.filter((info) =>
                type.includes(info.template_type)
              ),
            },
            {
              label: 'My Templates',
              options: templates
                .filter((item) => type.includes(item.template_type))
                .map((item) => ({
                  label: item.template_name,
                  value: item._id,
                })),
            },
          ]}
          onChange={(val) => handleTemplate(val)}
        />
      ) : null}

      {templates && templates.length >= 0 && type?.includes('Question') ? (
        <AntSelect
          style={{
            width: '100%',
          }}
          value={template}
          placeholder='Choose a Template To Modify'
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: '8px 0',
                }}
              />
            </>
          )}
          options={[
            {
              label: 'Sample Templates',
              options: temps.filter(
                (info) =>
                  type.includes(info.template_type) &&
                  (mcq
                    ? info.response_buttons.length > 0 &&
                      info.response_buttons[0] !== ' '
                    : info.response_buttons.length === 0)
              ),
            },
            {
              label: 'My Templates',
              options: templates
                .filter(
                  (item) =>
                    type.includes(item.template_type) &&
                    (mcq
                      ? item.response_buttons.length > 0 &&
                        item.response_buttons[0] !== ' '
                      : item.response_buttons.length === 0)
                )
                .map((item) => ({
                  label: item.template_name,
                  value: item._id,
                })),
            },
          ]}
          onChange={(val) => handleTemplate(val)}
        />
      ) : null}

      <p></p>

      {template ? (
        <>
          {!edit && !editSample ? (
            <>
              <Butto onClick={handleEdit}>Edit Template</Butto>
              <Butto style={{ marginLeft: '15px' }} onClick={handleEditCopy}>
                Copy This Template
              </Butto>
            </>
          ) : null}
          <p></p>
          {!editSample && (
            <TextField
              disabled={!edit}
              placeholder={template}
              value={newTemplate.template_name}
              onChange={(e) =>
                setNewTemplate({
                  ...newTemplate,
                  template_name: e.target.value,
                })
              }
              label='Unique Template Name'
              size='small'
              fullWidth
            />
          )}

          {!editSample && (
            <TextField
              disabled={!edit}
              multiline
              rows={7}
              value={newTemplate.body}
              onChange={(e) =>
                setNewTemplate({
                  ...newTemplate,
                  body: e.target.value,
                })
              }
              inputProps={{ maxLength: 1024 }}
              helperText={`${newTemplate.body.length}/1024 characters`}
              error={newTemplate.body.length === 1024}
              label='Template Body'
              size='small'
              fullWidth
            />
          )}

          {editSample && (
            <>
              <TextField
                value={newTemplate.template_name}
                placeholder={template}
                onChange={(e) =>
                  setNewTemplate({
                    ...newTemplate,
                    template_name: e.target.value,
                  })
                }
                label='Template Name'
                size='small'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <TextField
                multiline
                rows={7}
                value={newTemplate.body}
                onChange={(e) =>
                  setNewTemplate({
                    ...newTemplate,
                    body: e.target.value,
                  })
                }
                inputProps={{ maxLength: 1024 }}
                placeholder={newTemplate.body}
                InputLabelProps={{ shrink: true }}
                helperText={`${newTemplate.body.length}/1024 characters`}
                error={newTemplate.body.length === 1024}
                label='Template Body'
                size='small'
                fullWidth
              />
            </>
          )}

          {/\d/.test(template) &&
          templates.filter((info) => info._id === template)[0].response_buttons
            .length > 0 &&
          type.includes('Question') ? (
            <TextField
              disabled={!edit}
              value={newTemplate.response_buttons}
              onChange={(e) =>
                setNewTemplate({
                  ...newTemplate,
                  response_buttons: e.target.value,
                })
              }
              label='Button Options'
              size='small'
              fullWidth
            />
          ) : null}

          {editSample &&
          type.includes('Question') &&
          temps2.filter((info) => info.template_name === template)[0]
            .response_buttons.length > 0 ? (
            <TextField
              disabled={!newTemplate.template_type.includes('Question')}
              value={newTemplate.response_buttons}
              label='Button Options'
              onChange={(e) =>
                setNewTemplate({
                  ...newTemplate,
                  response_buttons: e.target.value,
                })
              }
              size='small'
              fullWidth
            />
          ) : null}

          {/\d/.test(template) &&
          templates.filter((info) => info._id === template)[0].response_buttons
            .length > 0 &&
          type.includes('Question') ? (
            <span>
              <i>You can add upto three options comma (,) separated</i>
            </span>
          ) : null}

          {editSample &&
          type.includes('Question') &&
          temps2.filter((info) => info.template_name === template)[0]
            .response_buttons.length > 0 ? (
            <span>
              <i>You can add upto three options comma (,) separated</i>
            </span>
          ) : null}
          <p></p>

          {edit ? (
            <Butto
              style={{ float: 'right' }}
              type='primary'
              onClick={editCopy ? addCopyTemplate : updateNewTemplate}>
              Save Template
            </Butto>
          ) : null}

          {editSample && (
            <Butto
              style={{ float: 'right' }}
              type='primary'
              onClick={addNewTemplate}>
              Save Template
            </Butto>
          )}

          {/* <div>
            <i>Buy Premium to edit message templates</i>
          </div> */}
        </>
      ) : null}
    </div>
  );
};

export default EditTemplates;
