import React, { useReducer } from 'react';
import axios from 'axios';
import AuthContext from './AuthContext';
import AuthReducer from './AuthReducer';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  STATE_RESET,
} from '../types';
import { API_URL } from '../../Constants';

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    email: localStorage.getItem('email'),
    loading: null,
    error: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const stateReset = async (resetState) => {
    dispatch({
      type: STATE_RESET,
      payload: resetState,
    });
  };

  const logout = () => {
    dispatch({
      type: LOGOUT,
    });
  };

  const signUp = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.post(
        API_URL + '/auth/register',
        formData,
        config
      );

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        payload: err.response.data,
      });
    }
  };

  const login = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.post(API_URL + '/auth', formData, config);

      if (res) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data,
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        email: state.email,
        error: state.error,
        login,
        logout,
        signUp,
        stateReset,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
