import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import axios from 'axios';
import { API_URL } from '../../Constants';

const DisplayMedia = ({ mediaId }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState();

  useEffect(() => {
    setUrl();
  }, []);

  async function fetchMedia() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const obj = {
      email: localStorage.email,
    };

    const res = await axios.get(
      API_URL + `/messages/media/${mediaId}`,
      { params: obj },
      config
    );
    setUrl(res.data);
    setLoading(false);
  }

  return (
    <>
      <Button
        style={{ textTransform: 'capitalize', padding: '0' }}
        onClick={() => {
          fetchMedia();
          setLoading(true);
          setOpen(true);
        }}>
        Click to View Media
      </Button>
      <Dialog
        disablePortal
        style={{ position: 'absolute' }}
        open={open}
        onClose={() => setOpen(false)}>
        <DialogContent>
          {url ? (
            url.includes('media.jpg') ||
            url.includes('media.jpeg') ||
            url.includes('media.png') ? (
              <embed src={url} />
            ) : (
              <a type='button' href={url} download>
                Download File
              </a>
            )
          ) : loading ? (
            <CircularProgress />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
          <Button onClick={() => setOpen(false)} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DisplayMedia.propTypes = {
  mediaId: PropTypes.string,
};

export default DisplayMedia;
