import React, { useState, useEffect, useContext, Fragment } from 'react';
import MessageList from './MessageList';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import ConversationContext from '../../Context/conversation/ConversationContext';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate } from 'react-router-dom';
import JobContext from '../../Context/job/JobContext';
import { useParams } from 'react-router-dom';
import { Button as AntButton, Input, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const Conversations = () => {
  let { query } = useParams();

  const navigate = useNavigate();
  const context = useContext(ConversationContext);
  const context2 = useContext(JobContext);

  const { getJobs, jobs } = context2;

  const {
    conversations,
    conversation_success,
    filter,
    subFilter,
    stateReset,
    getConversation,
    getCredits,
    credits,
  } = context;

  const [loading, setLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    async function fetchData() {
      // getJobs();
      getCredits();
    }

    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, [credits]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await getConversation(query);
    }

    if (!window.location.href.includes('chat/')) {
      if (query !== undefined) {
        stateReset({ subFilter: query });
      }
      fetchData();
    }
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    if (conversation_success) {
      stateReset({ conversation_success: false });
      const arr = conversations;
      if (filter === undefined || filter.length === 1) {
        setCandidates(conversations);
      } else {
        let filtered = arr.filter(
          (item) =>
            (item.status !== '' &&
              filter.join(',').includes(',' + item.status)) ||
            (item.job_id !== '' && filter.join(',').includes(',' + item.job_id))
        );

        setCandidates(filtered);
      }
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [conversation_success]);

  // const handleValue = (event) => {
  //   stateReset({ email: event.target.value });
  //   localStorage.setItem('email', event.target.value);
  //   setEmvalue(event.target.value);
  //   setLoading(true);
  //   getConversation();
  // };

  const handleFilter = async (e) => {
    const {
      target: { value },
    } = e;

    stateReset({
      filter: typeof value === 'string' ? value.split(',') : value,
    });
    const arr = conversations;
    setLoading(true);
    if (value === undefined || value.length === 1) {
      setCandidates(conversations);
    } else {
      let filtered = arr.filter(
        (item) =>
          (item.status !== '' && value.join(',').includes(',' + item.status)) ||
          (item.job_id !== '' && value.join(',').includes(',' + item.job_id))
      );

      setCandidates(filtered);
    }
    setLoading(false);
  };

  // const loadItems = async () => {
  //   setLoading(true);
  //   const config = {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //     },
  //   };

  //   const res = await axios.get(
  //     `https://monk-bot.azurewebsites.net/candidates/initiated?email=${localStorage.email}&filter=${subFilter}&page_number=${page}`,
  //     config
  //   );
  //   const newItems = await res.data.data;
  //   stateReset({
  //     conversations: [...conversations, ...newItems],
  //     conversation_success: true,
  //   });
  //   setPage((prevPage) => prevPage + 1);
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  // const handleScroll = () => {
  //   const elem = document.getElementById('chat-list-load');
  //   // Check if the user has scrolled to the bottom of the page
  //   if (window.innerHeight + elem.scrollTop === elem.offsetHeight) {
  //     loadItems();
  //   }
  // };

  return (
    <Component>
      <Paper elevation={0}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
            borderBottom: '1px solid #DADDE1',
            height: '54px',
          }}>
          <Grid
            item
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => navigate(`/`)}>
            <img
              width={120}
              style={{ float: 'left' }}
              src={require('../../assets/images/logo2.png')}
              alt='logo'
            />
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingRight: '10px',
            }}>
            <Tooltip title='Credits Balance'>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #b32800',
                  borderRadius: '16px',
                  padding: '2px 8px',
                  marginTop: '0px',
                  marginRight: '5px',
                }}>
                <AccountBalanceWalletIcon
                  style={{
                    background: 'none',
                    color: '#b32800',
                    marginRight: '4px',
                  }}
                />{' '}
                {credits}
              </span>
            </Tooltip>
            <Tooltip title='Logout'>
              <IconButton
                variant='contained'
                size='small'
                style={{
                  padding: '3px 10px',
                  background: 'none',
                  color: '#b32800',
                }}
                onClick={() => {
                  localStorage.clear();
                  navigate('/login');
                }}>
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </div>
      </Paper>

      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          margin: '0px 0 0 2px',
          padding: '8px',
          background: '#edfaf8',
          borderBottom: '1px solid #DADDE1',
        }}>
        <Button className='inactive-button' onClick={() => navigate('/jobs')}>
          Jobs
        </Button>
        <Button
          className='active-button'
          onClick={() => navigate('/')}
          style={{ marginLeft: '5px' }}>
          Chats
        </Button>
        <Button
          className='inactive-button-use'
          style={{ marginLeft: '8px' }}
          onClick={() => navigate('/usage')}>
          How To Use
        </Button>
      </div>

      <Grid
        container
        style={{
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0px 15px 10px 10px',
          alignItems: 'center',
        }}>
        <Grid item>
          <h3 style={{ color: '#b32800' }}>Filter</h3>
        </Grid>
        <Grid item xs={8}>
          <Select
            MenuProps={{ PaperProps: { sx: { maxHeight: 360 } } }}
            fullWidth
            multiple
            placeholder='By Status or Jobs'
            size='small'
            value={filter}
            onChange={handleFilter}>
            <ListSubheader>Status By Recruiter</ListSubheader>
            <MenuItem value='Interested'>Interested</MenuItem>
            <MenuItem value='Not Interested'>Not Interested</MenuItem>
            <ListSubheader>Selected</ListSubheader>
            <MenuItem value='Screen Select'>Screen Select</MenuItem>
            <MenuItem value='Interview Select'>Interview Select</MenuItem>
            <MenuItem value='Final HR Select'>Final HR Select</MenuItem>
            <ListSubheader>Rejected</ListSubheader>
            <MenuItem value='Screen Reject'>Screen Reject</MenuItem>
            <MenuItem value='Interview Reject'>Interview Reject</MenuItem>
            <MenuItem value='Final HR Reject'>Final HR Reject</MenuItem>
            <ListSubheader>Assessment</ListSubheader>
            <MenuItem value='Assessment Sent'>Assessment Sent</MenuItem>
            <MenuItem value='Assessment Taken'>Assessment Taken</MenuItem>
            <MenuItem value='Assessment Select'>Assessment Select</MenuItem>
            <MenuItem value='Assessment Reject'>Assessment Reject</MenuItem>
            <MenuItem value='Assessment No Show'>Assessment No Show</MenuItem>
            <ListSubheader>Other</ListSubheader>
            <MenuItem value='Job Offered'>Job Offered</MenuItem>
            <MenuItem value='Interview Dropout'>Interview Dropout</MenuItem>
            <ListSubheader>Jobs</ListSubheader>
            {jobs &&
              jobs.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.role}
                </MenuItem>
              ))}
          </Select>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0px 15px 10px 10px',
          alignItems: 'center',
        }}>
        <Grid item xs={12}>
          <Input
            placeholder='Search'
            allowClear
            prefix={<SearchOutlined />}
            onChange={(e) => setSearch(e.target.value)}
          />
          {/* <TextField
            label='Search'
            size='small'
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {search.length > 0 ? (
                    <IconButton onClick={() => setSearch('')}>
                      <ClearIcon />
                    </IconButton>
                  ) : null}
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> */}
        </Grid>
      </Grid>

      <div
        className='filter-buttons'
        style={{
          display: 'flex',
          justifyContent: 'left',
          margin: '0px 0 0 2px',
          padding: '8px',
          overflowX: 'auto',
        }}>
        <AntButton
          className={
            subFilter === 'All' ? 'active-smallbutton' : 'inactive-smallbutton'
          }
          onClick={() => navigate('/chats/All')}>
          All
        </AntButton>
        <AntButton
          className={
            subFilter === 'Interested'
              ? 'active-smallbutton'
              : 'inactive-smallbutton'
          }
          onClick={() => navigate('/chats/Interested')}
          style={{ marginLeft: '8px' }}>
          Interested
        </AntButton>
        <AntButton
          className={
            subFilter === 'Not Interested'
              ? 'active-smallbutton'
              : 'inactive-smallbutton'
          }
          onClick={() => navigate('/chats/Not Interested')}
          style={{ marginLeft: '8px' }}>
          Not Interested
        </AntButton>
        <AntButton
          className={
            subFilter === 'No Response'
              ? 'active-smallbutton'
              : 'inactive-smallbutton'
          }
          onClick={() => navigate('/chats/No Response')}
          style={{ marginLeft: '8px' }}>
          No Response
        </AntButton>
        <AntButton
          className={
            subFilter === 'Unread'
              ? 'active-smallbutton'
              : 'inactive-smallbutton'
          }
          onClick={() => navigate('/chats/Unread')}
          style={{ marginLeft: '8px' }}>
          Unread
        </AntButton>
      </div>

      <div id='chat-list-load'>
        {loading ? (
          <CircularProgress />
        ) : // : localStorage.isAdmin === 'true' ||
        //   localStorage.email === 'monkdev01@gmail.com' ? (
        //   <List sx={{ width: '100%' }}>
        //     {candidates &&
        //       candidates?.map((user) => {
        //         if (user.name.toLowerCase().includes(search.toLowerCase()))
        //           return (
        //             <ListItem
        //               key={user._id}
        //               secondaryAction={
        //                 <Checkbox
        //                   edge='end'
        //                   onChange={handleToggle(user._id)}
        //                   checked={checked.indexOf(user._id) !== -1}
        //                 />
        //               }
        //               disablePadding>
        //               <MessageList details={user} />
        //               <StyledDivider />
        //             </ListItem>
        //           );
        //         else return null;
        //       })}
        //   </List>
        // )
        candidates && candidates.length === 0 ? (
          'No Chats'
        ) : (
          candidates.map((user, index) => {
            if (
              user.name.toLowerCase().includes(search.toLowerCase()) ||
              user.phone.toString().includes(search.toLowerCase())
            )
              return (
                <Fragment key={index}>
                  {user.latest_message === null ? (
                    ''
                  ) : (
                    <>
                      <MessageList details={user} />
                      <StyledDivider />
                    </>
                  )}
                </Fragment>
              );
            else return null;
          })
        )}
      </div>
    </Component>
  );
};

const Component = styled(Box)`
  overflow: overlay;
  height: 99vh;
  width: 360px;
  border-right: 1px solid #dadde1;
`;

// const Image = styled(Avatar)`
//   float: right;
//   margin: 15px;
//   marginright: 30px;
// `;

const StyledDivider = styled(Divider)`
  margin: 0px;
  background-color: #e9edef;
  opacity: 0.6;
`;

export default Conversations;
