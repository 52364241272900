import React, { useState, useEffect, useContext, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import styled from '@emotion/styled';
import ConversationContext from '../../Context/conversation/ConversationContext';
import { useParams } from 'react-router-dom';
import './chat.css';
import AddStatus from './AddStatus';
import EditStatus from './EditStatus';

const CandidateDetails = () => {
  let { id } = useParams();

  const context = useContext(ConversationContext);

  const { candidate } = context;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line
  }, [id]);

  if (!window.location.href.includes('chat/'))
    return (
      // <Grid item xs={false} style={{ width: '50%' }} md={7} className='image' />
      <></>
    );

  return (
    <>
      <Grid
        id='CandidateDetails'
        container
        spacing={0}
        style={{
          backgroundColor: '#fff',
          height: '95vh',
          padding: '5px',
          //   marginTop: '-10px',
        }}>
        {/* <Grid item xs={12} className='textContainer'>
          <p className='candidateName'>{candidate?.name}</p>
        </Grid> */}
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}>
          <span>Status</span>
          <span>
            {candidate?.status ? (
              <>
                <span>{candidate?.status}</span>
                <EditStatus id={candidate?._id} stat={candidate?.status} />
              </>
            ) : (
              <AddStatus id={candidate?._id} />
            )}
          </span>
        </div>
      </Grid>
    </>
  );
};

const Image = styled(Avatar)`
  margin: 5px 10px 10px 10px;
  height: 35px;
  width: 35px;
`;

export default CandidateDetails;
