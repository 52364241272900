import React from 'react';
import AllJobs from './AllJobs';

const Admin = () => {
  if (
    localStorage.email !== 'jatin@recruitingmonk.com' &&
    localStorage.email !== 'ashfaq@recruitingmonk.com'
  )
    return <div>404 Page Not Found</div>;
  else
    return (
      <div style={{ background: '#edfaf8', height: '99vh' }}>
        Admin Panel
        <br />
        <br />
        <AllJobs />
      </div>
    );
};

export default Admin;
