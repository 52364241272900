import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

const DisplayMediaLink = (props) => {
  const { url } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        style={{
          textTransform: 'capitalize',
          color: '#fff',
          textDecoration: 'underline',
          padding: '0',
        }}
        onClick={() => {
          setOpen(true);
        }}>
        Click to View Media
      </Button>
      <Dialog
        disablePortal
        style={{ position: 'absolute' }}
        open={open}
        onClose={() => setOpen(false)}>
        <DialogContent>
          {url ? (
            url.includes('.jpg') ||
            url.includes('.jpeg') ||
            url.includes('.png') ||
            url.includes('.pdf') ? (
              <embed src={url} />
            ) : (
              <a type='button' href={url} download>
                Download File
              </a>
            )
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
          <Button onClick={() => setOpen(false)} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DisplayMediaLink.propTypes = {
  mediaId: PropTypes.string,
};

export default DisplayMediaLink;
