import {
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from '../types';
import SetAuthToken from '../../utils/SetAuthToken';

const AuthReducer = (state, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('email', action.payload.email);
      localStorage.setItem('plan_type', action.payload.plan_type);
      localStorage.setItem('credits_left', action.payload.credits_left);
      localStorage.setItem('plan_name', action.payload.plan_name);
      localStorage.setItem('company_type', action.payload.company_type);
      localStorage.setItem(
        'isAdmin',
        action.payload.email === 'monkdev01@gmail.com'
      );
      SetAuthToken(action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        email: action.payload.email,
        isAuthenticated: true,
        loading: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case REGISTER_FAIL:
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('company_type');
      localStorage.removeItem('credits_left');
      localStorage.removeItem('plan_name');
      localStorage.removeItem('plan_type');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        email: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
