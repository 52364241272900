import React, { useState, useContext, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import JobContext from '../../Context/job/JobContext';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TemplateContext from '../../Context/template/TemplateContext';
import EditTemplates from './EditTemplates';
// import CreateTemplates from './CreateTemplates';
import { message } from 'antd';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const EditJob = (props) => {
  const [openForm, setOpenForm] = useState(false);
  const [type, setType] = useState('');
  const [open, setOpen] = useState(props.openIt);
  const [state, setState] = useState({
    _id: '',
    role: '',
    location: '',
    skills: '',
    created_by: localStorage.email,
    mail_subject: '',
    first_template: '',
    second_template: '',
    third_template: '',
    fourth_template: '',
    fifth_template: '',
    sixth_template: '',
    seventh_template: '',
    monkbot_length: 0,
    client: '',
  });

  const { info } = props;
  const context = useContext(JobContext);
  const context2 = useContext(TemplateContext);

  const { updateJob, getJobs, update_success, job_error, stateReset } = context;
  const { templates } = context2;

  const [value, setValue] = useState(0);
  // const [value2, setValue2] = useState(0);
  // const [editing, setEditing] = useState(false);
  // const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (info) {
      setState({
        _id: info._id,
        role: info.role,
        location: info.location,
        skills: info.skills,
        created_by: localStorage.email,
        mail_subject: info.mail_subject,
        first_template: info.first_template,
        second_template: info.second_template,
        third_template: info.third_template,
        fourth_template: info.fourth_template,
        fifth_template: info.fifth_template,
        sixth_template: info.sixth_template,
        seventh_template: info.seventh_template,
        monkbot_length: info.monkbot_length,
        client: info.client,
      });
    }
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    if (update_success) {
      stateReset({ update_success: false });
      message.info('Job updated and sent for review');
      getJobs();
      props.reset();
      setState({
        role: '',
        location: '',
        skills: '',
        created_by: localStorage.email,
        mail_subject: '',
        first_template: '',
        second_template: '',
        third_template: '',
        fourth_template: '',
        fifth_template: '',
        sixth_template: '',
        seventh_template: '',
        monkbot_length: 0,
        client: '',
      });
      setValue(0);
      setOpen(false);
    }
    // eslint-disable-next-line
  }, [update_success]);

  useEffect(() => {
    if (job_error) {
      message.error(job_error);
      stateReset({ job_error: null });
    }
    // eslint-disable-next-line
  }, [job_error]);

  const handleTab = (event, newValue) => {
    event.preventDefault();
    // setValue(newValue);
  };

  const closeForm = () => {
    setOpenForm(false);
  };

  // const handleTab2 = (event, newValue) => {
  //   // event.preventDefault();
  //   setValue2(newValue);
  // };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const updateStat = (info) => {
    console.log(info);
    setState(info);
  };

  const handleUpdateJob = async (event) => {
    event.preventDefault();

    let A = state.third_template;
    let B = state.fourth_template;
    let C = state.fifth_template;
    let D = state.sixth_template;

    let nonEmptyVars = [];

    if (A !== '') {
      nonEmptyVars.push(A);
    }
    if (B !== '') {
      nonEmptyVars.push(B);
    }
    if (C !== '') {
      nonEmptyVars.push(C);
    }
    if (D !== '') {
      nonEmptyVars.push(D);
    }

    let nonEmptyVarsSet = new Set(nonEmptyVars);

    if (nonEmptyVarsSet.size === nonEmptyVars.length) {
      updateJob(state);
    } else {
      return message.error('You can ask one question only one time');
    }
  };

  // const parentEditing = () => {
  //   setEditing(true);
  // };

  // const parentNotEditing = () => {
  //   setEditing(false);
  //   setAlert(false);
  // };

  return (
    <>
      {/* <Tooltip title='Edit Job'>
        <EditFilled
          onClick={() => {
            setOpen(true);
          }}
        />
      </Tooltip> */}
      <Dialog
        open={open}
        onClose={() => {
          props.reset();
          setOpen(false);
        }}>
        <DialogTitle>
          <Tabs value={value} onChange={handleTab}>
            <Tab label='Edit Job' key={0} />
            {/* <Tab label='Add Templates' key={1} /> */}
            <Tab label='Message Sequence' key={1} />
          </Tabs>
        </DialogTitle>
        <TabPanel value={value} index={0}>
          <form
            onSubmit={() => {
              setValue(1);
              // setValue2(0);
            }}>
            <DialogContent style={{ padding: '0px 20px' }}>
              <TextField
                value={state.role}
                label='Role'
                size='small'
                required
                fullWidth
                name='role'
                onChange={handleChange}
              />

              {localStorage.company_type === 'Agency' ? (
                <>
                  <TextField
                    value={state.client}
                    label='Client Name'
                    size='small'
                    required
                    fullWidth
                    name='client'
                    onChange={handleChange}
                  />
                </>
              ) : null}

              <TextField
                value={state.location}
                label='Location'
                size='small'
                required
                fullWidth
                name='location'
                onChange={handleChange}
              />

              <TextField
                value={state.skills}
                label='Mandatory Skills (Comma (,) Separated)'
                size='small'
                required
                fullWidth
                name='skills'
                onChange={handleChange}
              />

              <TextField
                value={state.mail_subject}
                label='Mail Subject Line'
                multiline
                maxRows={2}
                size='small'
                fullWidth
                name='mail_subject'
                onChange={handleChange}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  props.reset();
                  setOpen(false);
                }}>
                Cancel
              </Button>
              <Button type='submit' autoFocus>
                Next
              </Button>
            </DialogActions>
          </form>
        </TabPanel>

        {/* <TabPanel value={value} index={1}>
          <div style={{ padding: '0px 30px' }}>
            <Tabs value={value2} onChange={handleTab2}>
              <Tab label='Create Templates' key={0} />
              <Tab label='Edit My Templates' key={1} />
            </Tabs>
          </div>
          <DialogContent>
            {alert ? (
              <Space
                direction='vertical'
                style={{
                  width: '100%',
                  marginBottom: '10px',
                }}>
                <Alert
                  closable
                  onClose={parentNotEditing}
                  message={
                    <span>
                      Alert !! There are unsaved changes on this step. Are you
                      sure you want to move forward?
                    </span>
                  }
                  description={
                    <span>Note: All unsaved changes will be lost.</span>
                  }
                  showIcon
                  type='warning'
                  action={
                    <Space direction='vertical'>
                      <Butto
                        size='small'
                        style={{ width: '100%' }}
                        type='primary'
                        danger
                        onClick={() => {
                          setAlert(false);
                        }}>
                        {' '}
                        Continue Editing{' '}
                      </Butto>
                      <Butto
                        size='small'
                        style={{ width: '100%' }}
                        onClick={() => {
                          setAlert(false);
                          setEditing(false);
                          setValue(2);
                        }}>
                        Go Next
                      </Butto>
                    </Space>
                  }
                />
              </Space>
            ) : null}
            <TabPanel value={value2} index={0}>
              <CreateTemplates
                parentEditing={parentEditing}
                parentNotEditing={parentNotEditing}
              />
            </TabPanel>
            <TabPanel value={value2} index={1}>
              <EditTemplates
                parentEditing={parentEditing}
                parentNotEditing={parentNotEditing}
              />
            </TabPanel>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                if (editing) setAlert(true);
                else setValue(0);
              }}>
              Back
            </Button>
            <Button
              onClick={() => {
                if (editing) setAlert(true);
                else setValue(2);
              }}>
              Next
            </Button>
          </DialogActions>
        </TabPanel> */}
        <TabPanel value={value} index={1}>
          <form onSubmit={handleUpdateJob}>
            <DialogContent>
            <Grid container>
                <Grid item md={5} className='whatsapp-builder'>
                  <div style={{ overflowY: 'scroll', height: '65vh' }}>
                    {state.first_template === '' ? (
                      <div
                        className='whatsapp-builder-button'
                        onClick={() => {
                          setType('Initial Message');
                          setOpenForm(true);
                        }}>
                        <i>+ Add Initial Message...</i>
                      </div>
                    ) : (
                      <div
                        className='msg-body'
                        onClick={() => {
                          setType('Initial Message');
                          setOpenForm(true);
                        }}>
                        {
                          templates.filter(
                            (info) =>
                              info.template_name === state.first_template
                          )[0].body
                        }
                      </div>
                    )}
                    <p className='option-button'>Interested</p>
                    <p className='option-button'>Not Looking For Job</p>
                    <p className='option-button'>Tell Me More</p>
                    <p></p>
                    {state.second_template === '' ? (
                      <div
                        className='whatsapp-builder-button'
                        onClick={() => {
                          setType('Know More');
                          setOpenForm(true);
                        }}>
                        <i>+ Add Know More Message...</i>
                      </div>
                    ) : (
                      <div
                        className='msg-body'
                        onClick={() => {
                          setType('Know More');
                          setOpenForm(true);
                        }}>
                        {
                          templates.filter(
                            (info) =>
                              info.template_name === state.second_template
                          )[0].body
                        }
                      </div>
                    )}
                    <p className='option-button'>Proceed</p>
                    <p className='option-button'>Not Interested</p>
                    <p></p>
                    {state.third_template === '' ? (
                      <div
                        className='whatsapp-builder-button'
                        onClick={() => {
                          setType('Question 1');
                          setOpenForm(true);
                        }}>
                        <i>+ Add Question 1 (Optional)...</i>
                      </div>
                    ) : (
                      <>
                        <div
                          className='msg-body'
                          onClick={() => {
                            setType('Question 1');
                            setOpenForm(true);
                          }}>
                          {
                            templates.filter(
                              (info) =>
                                info.template_name === state.third_template
                            )[0].body
                          }
                        </div>
                        <div style={{ marginTop: '5px' }}>
                          {templates
                            .filter(
                              (info) =>
                                info.template_name === state.third_template
                            )[0]
                            .response_buttons.map((info) =>
                              info !== '' ? (
                                <p className='option-button'>{info}</p>
                              ) : null
                            )}
                        </div>
                      </>
                    )}
                    <p></p>
                    {state.fourth_template === '' ? (
                      <div
                        className='whatsapp-builder-button'
                        onClick={() => {
                          setType('Question 2');
                          setOpenForm(true);
                        }}>
                        <i>+ Add Question 2 (Optional)...</i>
                      </div>
                    ) : (
                      <>
                        <div
                          className='msg-body'
                          onClick={() => {
                            setType('Question 2');
                            setOpenForm(true);
                          }}>
                          {
                            templates.filter(
                              (info) =>
                                info.template_name === state.fourth_template
                            )[0].body
                          }
                        </div>
                        <div style={{ marginTop: '5px' }}>
                          {templates
                            .filter(
                              (info) =>
                                info.template_name === state.fourth_template
                            )[0]
                            .response_buttons.map((info) =>
                              info !== '' ? (
                                <p className='option-button'>{info}</p>
                              ) : null
                            )}
                        </div>
                      </>
                    )}
                    <p></p>
                    {state.fifth_template === '' ? (
                      <div
                        className='whatsapp-builder-button'
                        onClick={() => {
                          setType('Question 3');
                          setOpenForm(true);
                        }}>
                        <i>+ Add Question 3 (Optional)...</i>
                      </div>
                    ) : (
                      <>
                        <div
                          className='msg-body'
                          onClick={() => {
                            setType('Question 3');
                            setOpenForm(true);
                          }}>
                          {
                            templates.filter(
                              (info) =>
                                info.template_name === state.fifth_template
                            )[0].body
                          }
                        </div>
                        <div style={{ marginTop: '5px' }}>
                          {templates
                            .filter(
                              (info) =>
                                info.template_name === state.fifth_template
                            )[0]
                            .response_buttons.map((info) =>
                              info !== '' ? (
                                <p className='option-button'>{info}</p>
                              ) : null
                            )}
                        </div>
                      </>
                    )}
                    <p></p>
                    {state.sixth_template === '' ? (
                      <div
                        className='whatsapp-builder-button'
                        onClick={() => {
                          setType('Question 4');
                          setOpenForm(true);
                        }}>
                        <i>+ Add Question 4 (Optional)...</i>
                      </div>
                    ) : (
                      <>
                        <div
                          className='msg-body'
                          onClick={() => {
                            setType('Question 4');
                            setOpenForm(true);
                          }}>
                          {
                            templates.filter(
                              (info) =>
                                info.template_name === state.sixth_template
                            )[0].body
                          }
                        </div>
                        <div style={{ marginTop: '5px' }}>
                          {templates
                            .filter(
                              (info) =>
                                info.template_name === state.sixth_template
                            )[0]
                            .response_buttons.map((info) =>
                              info !== '' ? (
                                <p className='option-button'>{info}</p>
                              ) : null
                            )}
                        </div>
                      </>
                    )}
                    <p></p>
                    {state.seventh_template === '' ? (
                      <div
                        className='whatsapp-builder-button'
                        onClick={() => {
                          setType('Thank You');
                          setOpenForm(true);
                        }}>
                        <i>+ Add Thank You Message...</i>
                      </div>
                    ) : (
                      <div
                        className='msg-body'
                        onClick={() => {
                          setType('Thank You');
                          setOpenForm(true);
                        }}>
                        {
                          templates.filter(
                            (info) =>
                              info.template_name === state.seventh_template
                          )[0].body
                        }
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item md={1}></Grid>
                <Grid item md={6}>
                  {openForm ? (
                    <EditTemplates
                      upd={updateStat}
                      state={state}
                      closeForm={closeForm}
                      type={type}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setValue(0)}>Back</Button>
              <Button type='submit'>Submit</Button>
            </DialogActions>
          </form>
        </TabPanel>
      </Dialog>
    </>
  );
};

export default EditJob;
