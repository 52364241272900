import {
  GET_JOBS_SUCCESS,
  GET_JOBS_FAILURE,
  CREATE_JOB_SUCCESS,
  UPDATE_JOB_SUCCESS,
  DELETE_JOB_SUCCESS,
  CREATE_JOB_FAILURE,
  UPDATE_JOB_FAILURE,
  DELETE_JOB_FAILURE,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  UPLOAD_FAILURE,
  UPLOAD_SUCCESS,
  GET_JOB_CANDIDATES_FAILURE,
  GET_JOB_CANDIDATES_SUCCESS,
  STATE_RESET,
} from '../types';

const JobReducer = (state, action) => {
  switch (action.type) {
    case GET_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.payload,
        get_job_success: true,
      };
    case CREATE_JOB_SUCCESS:
      return {
        ...state,
        create_success: true,
        jobs: [...state.jobs, action.payload],
      };
    case GET_TEAM_SUCCESS:
      return {
        ...state,
        team_members: action.payload,
      };
    case GET_JOB_CANDIDATES_SUCCESS:
      return {
        ...state,
        candidates: action.payload,
        candidate_success: true,
      };
    case DELETE_JOB_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.filter((item) => item._id !== action.payload),
        delete_success: true,
      };
    case UPDATE_JOB_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
        update_success: true,
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        upload_success: true,
        upload_message: action.payload,
      };
    case GET_JOBS_FAILURE:
    case UPLOAD_FAILURE:
    case UPDATE_JOB_FAILURE:
    case DELETE_JOB_FAILURE:
    case GET_JOB_CANDIDATES_FAILURE:
    case CREATE_JOB_FAILURE:
      return {
        ...state,
        job_error: action.payload,
      };
    case STATE_RESET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default JobReducer;
