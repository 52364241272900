import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatBox from './chat/ChatBox';
import Conversations from './chat/Conversations';
import Grid from '@mui/material/Grid';
import io from 'socket.io-client';
import ConversationContext from '../Context/conversation/ConversationContext';
import CandidateDetails from './chat/CandidateDetails';
// import file from '../assets/notification.mp3';

let socket;

const Home = () => {
  const navigate = useNavigate();

  const context = useContext(ConversationContext);

  const { getConversation, getMessages, conversation_success, stateReset } =
    context;

  async function handleJoin() {
    const phone_id = localStorage.phone_id;

    if (phone_id) {
      await socket.emit('join', { phone_id }, (error) => {
        if (error) {
          console.log(error);
        }
      });

      // console.log(socket);
    }
  }

  useEffect(() => {
    if (socket !== undefined) {
      // console.log('connected here and calling join');
      handleJoin();
    }
    // console.log('use ffect', socket);
  }, [socket]);

  useEffect(() => {
    // const audio = new Audio(file);
    if (!localStorage.token) navigate('/login');

    // socket = io.connect('https://monkbot-socket.recruitingmonk.com');

    socket = io.connect('https://monk-bot-socket.azurewebsites.net');

    // console.log('calaled socket');

    socket.on('Refresh', async (data) => {
      console.log(data);
      getConversation();

      if (window.location.href.includes('chat/')) {
        const id = window.location.href.split('chat/')[1];
        getMessages(id);
      }

      // audio.play();
    });

    // handleJoin();

    return () => {
      console.log('disconnect here');
      socket.close();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (conversation_success) {
      stateReset({ conversation_success: false });
      // console.log('refreshed home');
    }
    // eslint-disable-next-line
  }, [conversation_success]);

  if (!localStorage.token) return navigate('/login');

  return (
    <div style={{ display: 'flex' }}>
      <Conversations />
      <ChatBox />
      {/* <Grid
        container
        spacing={0}
        style={{
          backgroundColor: '#edfaf8',
          height: '99vh',
          width: `calc(100% - 361px)`,
        }}>
        <Grid item xs={9}>
          <ChatBox />
        </Grid>
        <Grid
          item
          xs={3}
          style={{ padding: '10px', backgroundColor: '#edfaf8' }}>
          <CandidateDetails />{' '}
        </Grid>
      </Grid> */}
    </div>
  );
};

export default Home;
