import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ConversationContext from '../../Context/conversation/ConversationContext';

const AddStatus = (props) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('');

  const { id } = props;
  const context = useContext(ConversationContext);

  const { addStatus, getConversation } = context;

  const PushStatus = async () => {
    addStatus(id, status);
    setStatus();
    getConversation();
    setOpen(false);
  };

  return (
    <>
      <Button
        variant='contained'
        style={{
          textTransform: 'capitalize',
          padding: '3px 10px',
          background: 'none',
          color: '#b32800',
        }}
        onClick={() => {
          setOpen(true);
        }}>
        Add Status
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Status</DialogTitle>
        <DialogContent>
          {id ? (
            <Select
              MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              fullWidth
              size='small'
              value={status}
              label='Status'
              onChange={(e) => setStatus(e.target.value)}>
              <ListSubheader>Selected</ListSubheader>
              <MenuItem value='Screen Select'>Screen Select</MenuItem>
              <MenuItem value='Interview Select'>Interview Select</MenuItem>
              <MenuItem value='Final HR Select'>Final HR Select</MenuItem>
              <ListSubheader>Rejected</ListSubheader>
              <MenuItem value='Screen Reject'>Screen Reject</MenuItem>
              <MenuItem value='Interview Reject'>Interview Reject</MenuItem>
              <MenuItem value='Final HR Reject'>Final HR Reject</MenuItem>
              <ListSubheader>Assessment</ListSubheader>
              <MenuItem value='Assessment Sent'>Assessment Sent</MenuItem>
              <MenuItem value='Assessment Taken'>Assessment Taken</MenuItem>
              <MenuItem value='Assessment Select'>Assessment Select</MenuItem>
              <MenuItem value='Assessment Reject'>Assessment Reject</MenuItem>
              <MenuItem value='Assessment No Show'>Assessment No Show</MenuItem>
              <ListSubheader>Other</ListSubheader>
              <MenuItem value='Interested'>Interested</MenuItem>
              <MenuItem value='Not Interested'>Not Interested</MenuItem>
              <MenuItem value='Job Offered'>Job Offered</MenuItem>
              <MenuItem value='Interview Dropout'>Interview Dropout</MenuItem>
            </Select>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={PushStatus} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddStatus;
