import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import JobContext from './JobContext';
import JobReducer from './JobReducer';
import AuthContext from '../../Context/auth/AuthContext';
import {
  GET_JOBS_SUCCESS,
  GET_JOBS_FAILURE,
  CREATE_JOB_SUCCESS,
  UPDATE_JOB_SUCCESS,
  DELETE_JOB_SUCCESS,
  CREATE_JOB_FAILURE,
  UPDATE_JOB_FAILURE,
  DELETE_JOB_FAILURE,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  UPLOAD_FAILURE,
  UPLOAD_SUCCESS,
  STATE_RESET,
  GET_JOB_CANDIDATES_FAILURE,
  GET_JOB_CANDIDATES_SUCCESS,
} from '../types';
import { API_URL } from '../../Constants';

const JobState = (props) => {
  const context2 = useContext(AuthContext);

  const { email } = context2;

  const initialState = {
    job_error: null,
    jobs: [],
    team_members: [],
    create_success: false,
    update_success: false,
    delete_success: false,
    get_job_success: false,
    upload_success: false,
    upload_message: '',
    candidates: [],
    candidate_success: false,
  };

  const [state, dispatch] = useReducer(JobReducer, initialState);

  const stateReset = async (resetState) => {
    dispatch({
      type: STATE_RESET,
      payload: resetState,
    });
  };

  const getJobs = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const obj = { email: localStorage.email ? localStorage.email : email };

    try {
      const res = await axios.get(API_URL + `/jobs?email=${obj.email}`, config);

      if (res) {
        dispatch({
          type: GET_JOBS_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_JOBS_FAILURE,
        payload: err.response.data,
      });
    }
  };

  const getCandidates = async (id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.get(API_URL + `/jobs/candidates/${id}`, config);

      if (res) {
        dispatch({
          type: GET_JOB_CANDIDATES_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_JOB_CANDIDATES_FAILURE,
        payload: err.response.data,
      });
    }
  };

  const getTeam = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const obj = { email: localStorage.email ? localStorage.email : email };

    try {
      const res = await axios.get(
        API_URL + `/jobs/team?email=${obj.email}`,
        config
      );

      if (res) {
        dispatch({
          type: GET_TEAM_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_TEAM_FAILURE,
        payload: err.response.data,
      });
    }
  };

  const postJob = async (receiver) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.post(API_URL + '/jobs', receiver, config);

      if (res) {
        dispatch({
          type: CREATE_JOB_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_JOB_FAILURE,
        payload: err.response.data,
      });
    }
  };

  const updateJob = async (receiver) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.post(
        API_URL + `/jobs/${receiver._id}`,
        receiver,
        config
      );

      dispatch({
        type: UPDATE_JOB_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_JOB_FAILURE,
        payload: err.response.data,
      });
    }
  };

  const deleteJob = async (id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.delete(API_URL + `/jobs/${id}`, config);

      dispatch({
        type: DELETE_JOB_SUCCESS,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: DELETE_JOB_FAILURE,
        payload: err.response.data,
      });
    }
  };

  const uploadCandidate = async (receiver) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.post(
        API_URL + '/candidates/excel',
        receiver,
        config
      );

      if (res) {
        dispatch({
          type: UPLOAD_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: UPLOAD_FAILURE,
        payload:
          err.response === undefined ? 'Server Error' : err.response.data,
      });
    }
  };

  return (
    <JobContext.Provider
      value={{
        job_error: state.job_error,
        jobs: state.jobs,
        team_members: state.team_members,
        create_success: state.create_success,
        update_success: state.update_success,
        delete_success: state.delete_success,
        get_job_success: state.get_job_success,
        upload_success: state.upload_success,
        upload_message: state.upload_message,
        candidates: state.candidates,
        candidate_success: state.candidate_success,
        stateReset,
        getJobs,
        postJob,
        getTeam,
        updateJob,
        deleteJob,
        uploadCandidate,
        getCandidates,
      }}>
      {props.children}
    </JobContext.Provider>
  );
};

export default JobState;
