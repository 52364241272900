import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import ReplayIcon from '@mui/icons-material/Replay';
import ConversationContext from '../../../Context/conversation/ConversationContext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const FollowUp = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('en');

  const { phone, id, closeIt } = props;
  const context = useContext(ConversationContext);

  const { sendTemplate } = context;

  const handleSubmit = async () => {
    let obj = {
      id: id,
      phone: phone,
      template_name: 'follow_up',
      language_code: value,
      component: null,
      email: localStorage.email,
    };
    if (value === 'en')
      obj.text =
        "Hey, We haven't heard back from you so just wanted to ask - are you interested to proceed further?";
    else if (value === 'en_US')
      obj.text =
        "Hey, Just following up as you didn't reply to our previous message, are you interested to proceed further? Let us know if you would  like to connect over a call to get a detailed explanation.";
    else if (value === 'en_GB')
      obj.text =
        "Hey, Just wanted to check in with you as we didn't receive any response from your end - do you have any queries related to the training program?";

    sendTemplate(obj);
    setValue();
    setOpen(false);
    closeIt();
  };

  return (
    <>
      <Button
        variant='standard'
        style={{ textTransform: 'capitalize' }}
        onClick={() => {
          setOpen(true);
        }}>
        <ReplayIcon fontSize='medium' />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Choose a Follow Up Template</DialogTitle>
        <DialogContent>
          {phone ? (
            <RadioGroup
              value={value}
              onChange={(e) => setValue(e.target.value)}>
              <FormControlLabel
                value='en'
                control={<Radio />}
                label="Hey, We haven't heard back from you so just wanted to ask - are you interested to proceed further?"
              />
              <br />
              <FormControlLabel
                value='en_US'
                control={<Radio />}
                label="Hey, Just following up as you didn't reply to our previous message, are you interested to proceed further?
Let us know if you would  like to connect over a call to get a detailed explanation."
              />
              <br />
              <FormControlLabel
                value='en_GB'
                control={<Radio />}
                label="Hey, Just wanted to check in with you as we didn't receive any response from your end - do you have any queries related to the training program?"
              />
            </RadioGroup>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            variant='standard'
            style={{ textTransform: 'capitalize' }}
            onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button
            variant='contained'
            style={{ textTransform: 'capitalize' }}
            onClick={handleSubmit}
            autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FollowUp;
