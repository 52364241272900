import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import TemplateContext from './TemplateContext';
import TemplateReducer from './TemplateReducer';
import AuthContext from '../auth/AuthContext';
import {
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAILURE,
  STATE_RESET,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAILURE,
} from '../types';
import { API_URL } from '../../Constants';

const TemplateState = (props) => {
  const context2 = useContext(AuthContext);

  const { email } = context2;

  const initialState = {
    template_error: null,
    templates: [],
    create_template_success: false,
    get_template_success: false,
    update_template_success: false,
  };

  const [state, dispatch] = useReducer(TemplateReducer, initialState);

  const stateReset = async (resetState) => {
    dispatch({
      type: STATE_RESET,
      payload: resetState,
    });
  };

  const getTemplates = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const obj = {
      email: localStorage.email ? localStorage.email : email,
      // job: id,
    };

    try {
      const res = await axios.get(
        API_URL + `/templates?email=${obj.email}`,
        config
      );

      if (res) {
        dispatch({
          type: GET_TEMPLATE_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_TEMPLATE_FAILURE,
        payload: err.response.data,
      });
    }
  };

  const postTemplate = async (receiver) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.post(API_URL + '/templates', receiver, config);

      if (res) {
        dispatch({
          type: CREATE_TEMPLATE_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_TEMPLATE_FAILURE,
        payload: err.response.data,
      });
    }
  };

  const updateTemplate = async (receiver) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.post(
        API_URL + `/templates/${receiver._id}`,
        receiver,
        config
      );

      dispatch({
        type: UPDATE_TEMPLATE_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_TEMPLATE_FAILURE,
        payload: err.response.data,
      });
    }
  };

  return (
    <TemplateContext.Provider
      value={{
        template_error: state.template_error,
        templates: state.templates,
        create_template_success: state.create_template_success,
        get_template_success: state.get_template_success,
        update_template_success: state.update_template_success,
        stateReset,
        getTemplates,
        postTemplate,
        updateTemplate,
      }}>
      {props.children}
    </TemplateContext.Provider>
  );
};

export default TemplateState;
