import React, { useState, useContext, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AuthContext from '../../Context/auth/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import './login.css';
import { message } from 'antd';

const Login = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [formObject, setFormObject] = useState({
    email: null,
    password: null,
  });
  const auth = useContext(AuthContext);

  const { token, error, login, stateReset } = auth;

  useEffect(() => {
    if (token && localStorage.token) {
      setTimeout(() => {
        navigate('/');
      }, 500);
    }
    if (error) {
      setLoading(false);
      message.error(error);
      stateReset({ error: null });
    }
  }, [error, token, navigate]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onLoginFormSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(formObject.email))
      return message.error('Please enter a valid email');

    setLoading(true);
    login(formObject);
  };

  //   if (isAuthenticated) return <Redirect to='/' />
  return (
    <Grid container alignContent='center' component='main' className='root'>
      <CssBaseline />
      <Grid
        item
        xs={12}
        style={{ height: '100%' }}
        md={5}
        component={Paper}
        elevation={2}
        square>
        <Card className='loginCard' elevation={3}>
          <div className='paper'>
            <Avatar className='avatar'>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
            <form className='form' onSubmit={onLoginFormSubmit} noValidate>
              <TextField
                variant='outlined'
                color='primary'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Enter Your Email Address'
                name='email'
                autoComplete='email'
                autoFocus
                type='email'
                onChange={(e) =>
                  setFormObject({ ...formObject, email: e.target.value })
                }
              />
              <TextField
                variant='outlined'
                margin='normal'
                color='primary'
                required
                fullWidth
                name='password'
                label='Enter Your Password'
                type='password'
                id='password'
                autoComplete='current-password'
                onChange={(e) =>
                  setFormObject({ ...formObject, password: e.target.value })
                }
              />
              {loading && <CircularProgress />}
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className='submit'>
                Sign In
              </Button>
              <Grid container justify='flex-end'>
                {/* <Grid item xs>
                    <Link href="#" variant="body2">
                    Forgot password?
                    </Link>
                </Grid> */}
                <Grid item>
                  <Link to='/signup' variant='body2'>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Card>
      </Grid>
      <Grid
        item
        xs={false}
        style={{ height: '100%' }}
        md={7}
        className='image'
      />
    </Grid>
  );
};

export default Login;
