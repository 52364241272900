import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { message } from 'antd';
import axios from 'axios';

const ApprovalForm = (props) => {
  const { openIt, info, reset, back } = props;

  const [temps, setTemps] = useState([]);
  const [user, setUser] = useState();
  const [state, setState] = useState({
    id: '',
    role: '',
    admin: localStorage.email,
    first_template_fb_name: '',
    second_template_fb_name: '',
    third_template_fb_name: '',
    fourth_template_fb_name: '',
    fifth_template_fb_name: '',
    sixth_template_fb_name: '',
    seventh_template_fb_name: '',
    monkbot_length: 0,
    contact: '',
  });

  async function fetchTemplates() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.get(
        `https://monk-bot.azurewebsites.net/admin/user-templates?admin=${localStorage.email}&email=${info.created_by}`,
        config
      );

      if (res) {
        setTemps(res.data.data);
      }

      const res2 = await axios.get(
        `https://monk-bot.azurewebsites.net/admin/user-details?admin=${localStorage.email}&email=${info.created_by}`,
        config
      );

      if (res2) {
        setUser(res2.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (info) {
      setState({
        ...state,
        id: info._id,
        role: info.role,
        first_template_fb_name: info.first_template_fb_name,
        second_template_fb_name: info.second_template_fb_name,
        third_template_fb_name: info.third_template_fb_name,
        fourth_template_fb_name: info.fourth_template_fb_name,
        fifth_template_fb_name: info.fifth_template_fb_name,
        sixth_template_fb_name: info.sixth_template_fb_name,
        seventh_template_fb_name: info.seventh_template_fb_name,
        monkbot_length: info.monkbot_length,
      });

      fetchTemplates();
    }
    // eslint-disable-next-line
  }, [info]);

  useEffect(() => {
    if (user !== undefined) {
      setState({
        ...state,
        contact: user.contact,
      });
    }
    // eslint-disable-next-line
  }, [user]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdateJob = async (event) => {
    event.preventDefault();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.post(
        `https://monk-bot.azurewebsites.net/admin/approve`,
        state,
        config
      );

      if (res) {
        message.success('Job Approved');
        back();
      }
    } catch (err) {
      console.log(err);
      message.error('Server Error');
    }
  };

  return (
    <>
      {openIt !== undefined &&
      openIt &&
      state._id !== '' &&
      user !== undefined &&
      temps.length > 0 ? (
        <Dialog open={openIt} onClose={() => reset()}>
          <DialogTitle>Phone Number Assigned - {user.phone}</DialogTitle>
          <form onSubmit={handleUpdateJob}>
            <DialogContent style={{ padding: '0px 20px' }}>
              <div>
                <b>First Template:</b>
                <p style={{ whiteSpace: 'pre-wrap' }}>
                  {
                    temps.filter(
                      (item) => item.template_name === info.first_template
                    )[0].body
                  }
                </p>
                <ul>
                  Options -
                  {temps
                    .filter(
                      (item) => item.template_name === info.first_template
                    )[0]
                    .response_buttons.map((it) => (
                      <li>{it}</li>
                    ))}
                </ul>
              </div>
              <TextField
                value={state.first_template_fb_name}
                label='First Template Name'
                size='small'
                required
                fullWidth
                name='first_template_fb_name'
                onChange={handleChange}
              />
              <p></p>
              <div>
                <b>Second Template:</b>
                <p style={{ whiteSpace: 'pre-wrap' }}>
                  {
                    temps.filter(
                      (item) => item.template_name === info.second_template
                    )[0].body
                  }
                </p>
                <ul>
                  Options -
                  {temps
                    .filter(
                      (item) => item.template_name === info.second_template
                    )[0]
                    .response_buttons.map((it) => (
                      <li>{it}</li>
                    ))}
                </ul>
              </div>
              <TextField
                value={state.second_template_fb_name}
                label='Second Template Name'
                size='small'
                required
                fullWidth
                name='second_template_fb_name'
                onChange={handleChange}
              />
              {info.third_template !== '' ? (
                <>
                  <p></p>
                  <div>
                    <b>Third Template:</b>
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                      {
                        temps.filter(
                          (item) => item.template_name === info.third_template
                        )[0].body
                      }
                    </p>
                    <ul>
                      Options -
                      {temps
                        .filter(
                          (item) => item.template_name === info.third_template
                        )[0]
                        .response_buttons.map((it) => (
                          <li>{it}</li>
                        ))}
                    </ul>
                  </div>
                  <TextField
                    value={state.third_template_fb_name}
                    label='Third Template Name'
                    size='small'
                    required
                    fullWidth
                    name='third_template_fb_name'
                    onChange={handleChange}
                  />
                </>
              ) : null}

              {info.fourth_template !== '' ? (
                <>
                  <p></p>
                  <div>
                    <b>Fourth Template:</b>
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                      {
                        temps.filter(
                          (item) => item.template_name === info.fourth_template
                        )[0].body
                      }
                    </p>
                    <ul>
                      Options -
                      {temps
                        .filter(
                          (item) => item.template_name === info.fourth_template
                        )[0]
                        .response_buttons.map((it) => (
                          <li>{it}</li>
                        ))}
                    </ul>
                  </div>
                  <TextField
                    value={state.fourth_template_fb_name}
                    label='Fourth Template Name'
                    size='small'
                    required
                    fullWidth
                    name='fourth_template_fb_name'
                    onChange={handleChange}
                  />
                </>
              ) : null}

              {info.fifth_template !== '' ? (
                <>
                  <p></p>
                  <div>
                    <b>Fifth Template:</b>
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                      {
                        temps.filter(
                          (item) => item.template_name === info.fifth_template
                        )[0].body
                      }
                    </p>
                    <ul>
                      Options -
                      {temps
                        .filter(
                          (item) => item.template_name === info.fifth_template
                        )[0]
                        .response_buttons.map((it) => (
                          <li>{it}</li>
                        ))}
                    </ul>
                  </div>
                  <TextField
                    value={state.fifth_template_fb_name}
                    label='Fifth Template Name'
                    size='small'
                    required
                    fullWidth
                    name='fifth_template_fb_name'
                    onChange={handleChange}
                  />
                </>
              ) : null}

              {info.sixth_template !== '' ? (
                <>
                  <p></p>
                  <div>
                    <b>Sixth Template:</b>
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                      {
                        temps.filter(
                          (item) => item.template_name === info.sixth_template
                        )[0].body
                      }
                    </p>
                    <ul>
                      Options -
                      {temps
                        .filter(
                          (item) => item.template_name === info.sixth_template
                        )[0]
                        .response_buttons.map((it) => (
                          <li>{it}</li>
                        ))}
                    </ul>
                  </div>
                  <TextField
                    value={state.sixth_template_fb_name}
                    label='Sixth Template Name'
                    size='small'
                    required
                    fullWidth
                    name='sixth_template_fb_name'
                    onChange={handleChange}
                  />
                </>
              ) : null}

              <>
                <p></p>
                <div>
                  <b>Last Template:</b>
                  <p style={{ whiteSpace: 'pre-wrap' }}>
                    {
                      temps.filter(
                        (item) => item.template_name === info.seventh_template
                      )[0].body
                    }
                  </p>
                </div>
                <TextField
                  value={state.seventh_template_fb_name}
                  label='Last Template Name'
                  size='small'
                  required
                  fullWidth
                  name='seventh_template_fb_name'
                  onChange={handleChange}
                />
              </>

              <>
                <p></p>
                <div>
                  <b>Total Questions:</b>
                  <p></p>
                </div>
                <TextField
                  type='number'
                  value={state.monkbot_length}
                  label='Total Questions Count'
                  size='small'
                  required
                  fullWidth
                  name='monkbot_length'
                  onChange={handleChange}
                />
              </>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => reset()}>Cancel</Button>
              <Button type='submit' autoFocus>
                Submit
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      ) : null}
    </>
  );
};

export default ApprovalForm;
