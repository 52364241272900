import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Select from '@mui/material/Select';
import ConversationContext from '../../Context/conversation/ConversationContext';
import { useEffect } from 'react';

const EditStatus = (props) => {
  const [open, setOpen] = useState(false);

  const { id, stat } = props;
  const [status, setStatus] = useState();
  const context = useContext(ConversationContext);

  const { addStatus, getConversation } = context;

  const PushStatus = async () => {
    addStatus(id, status);
    setStatus();
    getConversation(); // have to change this for filters (add state in store)
    setOpen(false);
  };

  useEffect(() => {
    if (stat) setStatus(stat);
  }, [stat]);

  return (
    <>
      <IconButton
        color='primary'
        onClick={() => {
          setOpen(true);
        }}>
        <EditIcon style={{ color: '#b32800' }} />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Edit Status</DialogTitle>
        <DialogContent>
          {id ? (
            <Select
              MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              fullWidth
              size='small'
              value={status}
              label='Status'
              onChange={(e) => setStatus(e.target.value)}>
              <ListSubheader>Selected</ListSubheader>
              <MenuItem value='Screen Select'>Screen Select</MenuItem>
              <MenuItem value='Interview Select'>Interview Select</MenuItem>
              <MenuItem value='Final HR Select'>Final HR Select</MenuItem>
              <ListSubheader>Rejected</ListSubheader>
              <MenuItem value='Screen Reject'>Screen Reject</MenuItem>
              <MenuItem value='Interview Reject'>Interview Reject</MenuItem>
              <MenuItem value='Final HR Reject'>Final HR Reject</MenuItem>
              <ListSubheader>Assessment</ListSubheader>
              <MenuItem value='Assessment Sent'>Assessment Sent</MenuItem>
              <MenuItem value='Assessment Taken'>Assessment Taken</MenuItem>
              <MenuItem value='Assessment Select'>Assessment Select</MenuItem>
              <MenuItem value='Assessment Reject'>Assessment Reject</MenuItem>
              <MenuItem value='Assessment No Show'>Assessment No Show</MenuItem>
              <ListSubheader>Other</ListSubheader>
              <MenuItem value='Interested'>Interested</MenuItem>
              <MenuItem value='Not Interested'>Not Interested</MenuItem>
              <MenuItem value='Job Offered'>Job Offered</MenuItem>
              <MenuItem value='Interview Dropout'>Interview Dropout</MenuItem>
            </Select>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={PushStatus} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EditStatus.propTypes = {
  id: PropTypes.string,
  stat: PropTypes.string,
};

export default EditStatus;
