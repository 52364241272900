export const temps = [
  // {
  //   label: (
  //     <span style={{ color: '#000' }}>
  //       Pre-defined Templates <b style={{ color: '#b32800' }}>(Mandatory)</b>
  //     </span>
  //   ),
  //   options: [
  //     {
  //       id: 1,
  //       template_name: 'Initial Message',
  //       template_type: 'Initial Message',
  //       label: 'Initial Message',
  //       value: 'Initial Message',
  //       body: `Hello {{Candidate Name}},

  //       This message is on behalf of the hiring at XYZ Technologies for "UI Developer - Reactjs"

  //       Happen to bounce on your resume on naukri & the work you have done around UI dev seems pretty intriguing.

  //       To talk to our recruiter live on whatsapp in few mins from now, please choose an intended action from the below buttons to move to next steps.`,
  //       response_buttons: ['Interested', 'Not Looking for Job', 'Tell Me More'],
  //     },
  //     {
  //       id: 2,
  //       template_name: 'Know More Message',
  //       template_type: 'Know More',
  //       label: 'Know More Message',
  //       value: 'Know More',
  //       body: `1. XYZ is an IT - Product based software company,
  //   2. XYZ was founded in the year 2008 and launched our First Flagship product in 2012
  //   3. No of Employees : 250+

  //   LinkedIn URL: url here
  //   Glassdoor URL: url here

  //   I hope the above info helped you get some insights about the company.

  //   We've 4-5 general questions around your tech stack & recent project. Post which our Recruiter will interact with you.`,
  //       response_buttons: ['Proceed', 'Not Interested'],
  //     },
  //     {
  //       id: 7,
  //       template_name: 'Thank you Message',
  //       template_type: 'Thank You',
  //       label: 'Thank you Message',
  //       value: 'Thank you',
  //       body: `Thanks {{Candidate Name}} for taking time to answer these questions. We're looping you to our Recruiter. You'd recieve a message shortly on the next steps.`,
  //       response_buttons: [],
  //     },
  //   ],
  // },
  {
    id: 1,
    template_name: 'Initial Message',
    template_type: 'Initial Message',
    label: 'Initial Message - Sample',
    value: 'Initial Message',
    body: `Hello {{Candidate Name}},

        This message is on behalf of the hiring at XYZ Technologies for "UI Developer - Reactjs"

        Happen to bounce on your resume on naukri & the work you have done around UI dev seems pretty intriguing.

        To talk to our recruiter live on whatsapp in few mins from now, please choose an intended action from the below buttons to move to next steps.`,
    response_buttons: ['Interested', 'Not Looking for Job', 'Tell Me More'],
  },
  {
    id: 2,
    template_name: 'Know More',
    template_type: 'Know More',
    label: 'Know More Message - Sample',
    value: 'Know More',
    body: `1. XYZ is an IT - Product based software company,
    2. XYZ was founded in the year 2008 and launched our First Flagship product in 2012
    3. No of Employees : 250+

    LinkedIn URL: url here
    Glassdoor URL: url here

    I hope the above info helped you get some insights about the company.

    We've 4-5 general questions around your tech stack & recent project. Post which our Recruiter will interact with you.`,
    response_buttons: ['Proceed', 'Not Interested'],
  },
  {
    id: 7,
    template_name: 'Thank you',
    template_type: 'Thank You',
    label: 'Thank you Message - Sample',
    value: 'Thank you',
    body: `Thanks {{Candidate Name}} for taking time to answer these questions. We're looping you to our Recruiter. You'd recieve a message shortly on the next steps.`,
    response_buttons: [],
  },
  {
    id: 3,
    template_name: 'Experience',
    template_type: 'Question',
    label: (
      <span>
        Experience in a particular skill or technology{' '}
        <i style={{ color: 'grey', fontSize: '12px' }}>(Type: MCQ)</i>
      </span>
    ),
    value: 'Experience',
    body: `How many years you have been using react redux?`,
    response_buttons: ['Less than a year', '1-2 years', '2+ years'],
  },
  {
    id: 4,
    template_name: 'Role Explanation',
    template_type: 'Question',
    label: (
      <span>
        Detailed explanation on last role or project{' '}
        <i style={{ color: 'grey', fontSize: '12px' }}>(Type: Text)</i>
      </span>
    ),
    value: 'Role Explanation',
    body: `Can you help us understand, what has been your recent role been in your current/latest project? Were you been involved in design or enhancement of the project.

A quick 50-60 word explanation would really help us get a hindsight of your current/latest role.
    
P.S: Post this you'll be directed for a live chat with our recruiter.`,
    response_buttons: [],
  },
  {
    id: 5,
    template_name: 'Notice Period',
    template_type: 'Question',
    label: (
      <span>
        What is the notice period?{' '}
        <i style={{ color: 'grey', fontSize: '12px' }}>(Type: MCQ)</i>
      </span>
    ),
    value: 'Notice Period',
    body: `What is your notice period?`,
    response_buttons: [
      'Less than 30 days',
      'Between 30 and 60 days',
      'More than 60 days',
    ],
  },
  {
    id: 6,
    template_name: 'CTC',
    template_type: 'Question',
    label: (
      <span>
        What is your current CTC?{' '}
        <i style={{ color: 'grey', fontSize: '12px' }}>(Type: Text)</i>
      </span>
    ),
    value: 'CTC',
    body: `What is your current CTC?`,
    response_buttons: [],
  },
  //   ],
  // },
];

export const temps2 = [
  {
    id: 1,
    template_name: 'Initial Message',
    template_type: 'Initial Message',
    body: `Hello {{Candidate Name}},

This message is on behalf of the hiring at XYZ Technologies for "UI Developer - Reactjs"
        
Happen to bounce on your resume on naukri & the work you have done around UI dev seems pretty intriguing.
        
To talk to our recruiter live on whatsapp in few mins from now, please choose an intended action from the below buttons to move to next steps.`,
    response_buttons: ['Interested', 'Not Looking for Job', 'Tell Me More'],
  },
  {
    id: 2,
    template_name: 'Know More',
    template_type: 'Know More',
    body: `1. XYZ is an IT - Product based software company,
2. XYZ was founded in the year 2008 and launched our First Flagship product in 2012
3. No of Employees : 250+
    
LinkedIn URL: url here
Glassdoor URL: url here
    
I hope the above info helped you get some insights about the company.
    
We've 4-5 general questions around your tech stack & recent project. Post which our Recruiter will interact with you.`,
    response_buttons: ['Proceed', 'Not Interested'],
  },
  {
    id: 7,
    template_name: 'Thank you',
    template_type: 'Thank You',
    body: `Thanks {{Candidate Name}} for taking time to answer these questions. We're looping you to our Recruiter. You'd recieve a message shortly on the next steps.`,
    response_buttons: [],
  },
  {
    id: 3,
    template_name: 'Experience',
    template_type: 'Question',
    body: `How many years you have been using react redux?`,
    response_buttons: ['Less than a year', '1-2 years', '2+ years'],
  },
  {
    id: 4,
    template_name: 'Role Explanation',
    template_type: 'Question',
    body: `Can you help us understand, what has been your recent role been in your current/latest project? Were you been involved in design or enhancement of the project.

A quick 50-60 word explanation would really help us get a hindsight of your current/latest role.
    
P.S: Post this you'll be directed for a live chat with our recruiter.`,
    response_buttons: [],
  },
  {
    id: 5,
    template_name: 'Notice Period',
    template_type: 'Question',
    label: 'Notice Period',
    value: 'Notice Period',
    body: `What is your notice period?`,
    response_buttons: [
      'Less than 30 days',
      'Between 30 and 60 days',
      'More than 60 days',
    ],
  },
  {
    id: 6,
    template_name: 'CTC',
    template_type: 'Question',
    label: 'CTC',
    value: 'CTC',
    body: `What is your current CTC?`,
    response_buttons: [],
  },
];
