import React, { useState, useContext, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AuthContext from '../../Context/auth/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import './login.css';
import { message } from 'antd';

const SignUp = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [formObject, setFormObject] = useState({
    email: null,
    password: null,
    confirm_password: null,
    company_type: null,
    linkedin_url: null,
    contact: null,
    referral_code: null,
  });

  const auth = useContext(AuthContext);

  const { token, error, signUp, stateReset } = auth;

  useEffect(() => {
    if (token && localStorage.token) {
      setTimeout(() => {
        navigate('/');
      }, 500);
    }
    if (error) {
      setLoading(false);
      message.error(error);
      stateReset({ error: null });
    }
  }, [error, token, navigate]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onFormSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(formObject.email))
      return message.error('Please enter a valid email');
    else if (
      formObject.referral_code.toUpperCase() !== 'WELCOME50' &&
      formObject.referral_code.toUpperCase() !== 'WELCOMEX100' &&
      formObject.referral_code.toUpperCase() !== 'WELCOME50X' &&
      formObject.referral_code.toUpperCase() !== 'WELCOME100X'
    )
      return message.error('Invalid Referral Code');
    else if (
      formObject.email.includes('gmail') ||
      formObject.email.includes('yahoo') ||
      formObject.email.includes('hotmail')
    )
      return message.error('Please use your organization email');

    if (formObject.password !== formObject.confirm_password) {
      setLoading(false);
      message.error('Passwords should Match!');
    } else {
      setLoading(true);
      signUp(formObject);
    }
  };

  //   if (isAuthenticated) return <Redirect to='/' />
  return (
    <Grid container alignContent='center' component='main' className='root'>
      <CssBaseline />
      <Grid
        item
        xs={12}
        style={{ height: '100%' }}
        md={5}
        component={Paper}
        elevation={2}
        square>
        <Card className='loginCard' elevation={3}>
          <div className='paper'>
            <Avatar className='avatar'>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign Up
            </Typography>
            <form className='form' onSubmit={onFormSubmit}>
              <TextField
                variant='outlined'
                color='primary'
                size='small'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Enter Your Email Address'
                name='email'
                autoComplete='email'
                autoFocus
                type='email'
                onChange={(e) =>
                  setFormObject({ ...formObject, email: e.target.value })
                }
              />
              <TextField
                variant='outlined'
                margin='normal'
                color='primary'
                required
                size='small'
                fullWidth
                name='password'
                label='Enter Your Password'
                type='password'
                id='password'
                autoComplete='current-password'
                onChange={(e) =>
                  setFormObject({ ...formObject, password: e.target.value })
                }
              />
              <TextField
                variant='outlined'
                margin='normal'
                color='primary'
                required
                size='small'
                fullWidth
                name='confirm-password'
                label='Confirm Password'
                type='password'
                id='password'
                autoComplete='current-password'
                onChange={(e) =>
                  setFormObject({
                    ...formObject,
                    confirm_password: e.target.value,
                  })
                }
              />
              <FormControl size='small' fullWidth required>
                <InputLabel id='company_type'>Type</InputLabel>
                <Select
                  labelId='company_type'
                  value={formObject.company_type}
                  label='Company Type'
                  fullWidth
                  name='company_type'
                  onChange={(e) =>
                    setFormObject({
                      ...formObject,
                      company_type: e.target.value,
                    })
                  }>
                  <MenuItem key={1} value='Agency'>
                    Agency
                  </MenuItem>
                  <MenuItem key={2} value='Corporate'>
                    Corporate
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='outlined'
                color='primary'
                margin='normal'
                size='small'
                required
                fullWidth
                id='linkedin_url'
                label='Company Linkedin URL'
                name='linkedin_url'
                autoFocus
                type='url'
                onChange={(e) =>
                  setFormObject({ ...formObject, linkedin_url: e.target.value })
                }
              />

              <TextField
                variant='outlined'
                color='primary'
                margin='normal'
                size='small'
                required
                fullWidth
                id='contact'
                label='Whatsapp Number'
                name='contact'
                autoFocus
                type='number'
                onChange={(e) =>
                  setFormObject({ ...formObject, contact: e.target.value })
                }
              />
              <TextField
                variant='outlined'
                color='primary'
                margin='normal'
                size='small'
                required
                fullWidth
                id='referral_code'
                label='Referral Code'
                name='referral_code'
                autoFocus
                onChange={(e) =>
                  setFormObject({
                    ...formObject,
                    referral_code: e.target.value,
                  })
                }
              />
              {loading && <CircularProgress />}
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className='submit'>
                Sign Up
              </Button>
              <Grid container justify='flex-end'>
                {/* <Grid item xs>
                    <Link href="#" variant="body2">
                    Forgot password?
                    </Link>
                </Grid> */}
                <Grid item>
                  <Link to='/login' variant='body2'>
                    {'Already have an account? Sign In'}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Card>
      </Grid>
      <Grid
        item
        xs={false}
        style={{ height: '100%' }}
        md={7}
        className='image'
      />
    </Grid>
  );
};

export default SignUp;
