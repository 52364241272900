import React, { useEffect, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Tooltip, Card } from 'antd';
import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';
import './job/jobs.css';
import { useNavigate } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ConversationContext from '../Context/conversation/ConversationContext';

const { Meta } = Card;

const Usage = () => {
  const navigate = useNavigate();
  const context = useContext(ConversationContext);
  const [use, setUse] = useState();

  const { credits, getCredits } = context;

  useEffect(() => {
    if (!localStorage.token) navigate('/login');

    getCredits();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, [credits]);

  return (
    <React.Fragment>
      <Grid container>
        <Component>
          <Paper elevation={0}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#fff',
                borderBottom: '1px solid #DADDE1',
                height: '54px',
              }}>
              <Grid
                item
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => navigate(`/`)}>
                <img
                  width={120}
                  style={{ float: 'left' }}
                  src={require('../assets/images/logo2.png')}
                  alt='logo'
                />
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '10px',
                }}>
                <Tooltip title='Credits Balance'>
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #b32800',
                      borderRadius: '16px',
                      padding: '2px 8px',
                      marginTop: '0px',
                      marginRight: '5px',
                    }}>
                    <AccountBalanceWalletIcon
                      style={{
                        background: 'none',
                        color: '#b32800',
                        marginRight: '4px',
                      }}
                    />{' '}
                    {credits}
                  </span>
                </Tooltip>
                <Tooltip title='Logout'>
                  <IconButton
                    variant='contained'
                    size='small'
                    style={{
                      padding: '3px 10px',
                      background: 'none',
                      color: '#b32800',
                    }}
                    onClick={() => {
                      localStorage.clear();
                      navigate('/login');
                    }}>
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </div>
          </Paper>

          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              margin: '0px 0 0 2px',
              padding: '8px',
              background: '#edfaf8',
              borderBottom: '1px solid #DADDE1',
            }}>
            <Button
              className='inactive-button'
              onClick={() => navigate('/jobs')}>
              Jobs
            </Button>
            <Button
              onClick={() => navigate('/')}
              style={{ marginLeft: '8px' }}
              className='inactive-button'>
              Chats
            </Button>
            <Button
              className='active-button-use'
              onClick={() => navigate('/usage')}>
              How To Use
            </Button>
          </div>

          <div>
            <Card
              key={1}
              style={{
                width: '100%',
                marginTop: 15,
                borderRadius: '8px',
              }}
              className={use === 'pdf' ? 'active-card' : 'not-active'}>
              <Meta
                style={{ cursor: 'pointer' }}
                onClick={() => setUse('pdf')}
                title='PDF Guide'
                description={
                  <>
                    <p>A brief descriptive PDF to get started</p>
                  </>
                }
              />
            </Card>

            {/* <Card
              key={2}
              style={{
                width: '100%',
                marginTop: 15,
                borderRadius: '8px',
              }}
              className={use === 'video' ? 'active-card' : 'not-active'}>
              <Meta
                style={{ cursor: 'pointer' }}
                onClick={() => setUse('video')}
                title='Video Tutorial'
                description={
                  <>
                    <p>A tutorial video to get you started</p>
                  </>
                }
              />
            </Card> */}
          </div>
        </Component>
        <div
          style={{
            width: 'calc(100vw - 460px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}>
          {use !== undefined && use === 'pdf' ? (
            <embed
              style={{
                width: '100%',
                height: '600px',
              }}
              src='/MonkBOT_Guide.pdf'
            />
          ) : null}

          {use !== undefined && use === 'video' ? (
            <iframe
              src='https://player.vimeo.com/video/799467512?autoplay=1&amp;h=984d8d6080'
              allow='autoplay; fullscreen; picture-in-picture'
              style={{
                width: '100%',
                height: '600px',
              }}
            />
          ) : null}
        </div>
      </Grid>
    </React.Fragment>
  );
};

const Component = styled(Box)`
  overflow: overlay;
  height: 99vh;
  width: 360px;
  border-right: 1px solid #dadde1;
`;

export default Usage;
