import {
  GET_CONVERSATION,
  CONVERSATION_ERROR,
  GET_MESSAGES,
  SEND_MESSAGE,
  MESSAGE_ERROR,
  STATE_RESET,
  CANDIDATE_INFO,
  ADD_STATUS,
  SEND_TEMPLATE_SUCCESS,
  SEND_TEMPLATE_ERROR,
  MARK_AS_READ,
  GET_CREDITS,
} from '../types';

const ConversationReducer = (state, action) => {
  switch (action.type) {
    case GET_CONVERSATION:
      return {
        ...state,
        conversations: action.payload,
        conversation_success: true,
      };
    case CANDIDATE_INFO:
      return {
        ...state,
        candidate: action.payload,
      };
    case GET_CREDITS:
      localStorage.setItem('plan_type', action.payload.plan_type);
      localStorage.setItem('phone_id', action.payload.phone_id);
      return {
        ...state,
        credits: action.payload.credits_left,
      };
    case GET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
        message_success: true,
      };
    case MARK_AS_READ:
      return {
        ...state,
        mark_read_success: true,
        messages: {
          unread: [],
          read: [...state.messages.read, ...state.messages.unread],
        },
      };
    case SEND_MESSAGE:
      return {
        ...state,
        message_success: true,
        messages: {
          unread: [],
          read: [
            ...state.messages.read,
            ...state.messages.unread,
            action.payload,
          ],
        },
      };
    case SEND_TEMPLATE_SUCCESS:
      return {
        ...state,
        message_success: true,
        messages: {
          unread: [],
          read: [
            ...state.messages.read,
            ...state.messages.unread,
            action.payload,
          ],
        },
      };
    case ADD_STATUS:
      return {
        ...state,
        candidate: {
          ...state.candidate,
          status: action.payload,
        },
      };
    case CONVERSATION_ERROR:
    case MESSAGE_ERROR:
    case SEND_TEMPLATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case STATE_RESET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default ConversationReducer;
