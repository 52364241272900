import {
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAILURE,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
  STATE_RESET,
  UPDATE_TEMPLATE_FAILURE,
  UPDATE_TEMPLATE_SUCCESS,
} from '../types';

const TemplateReducer = (state, action) => {
  switch (action.type) {
    case GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: action.payload,
        get_template_success: true,
      };
    case CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        create_template_success: true,
        templates: [...state.templates, action.payload],
      };
    case UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: state.templates.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
        update_template_success: true,
      };
    case GET_TEMPLATE_FAILURE:
    case UPDATE_TEMPLATE_FAILURE:
    case CREATE_TEMPLATE_FAILURE:
      return {
        ...state,
        template_error: action.payload,
      };
    case STATE_RESET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default TemplateReducer;
