import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import ConversationContext from './ConversationContext';
import ConversationReducer from './ConversationReducer';
import AuthContext from '../../Context/auth/AuthContext';
import {
  GET_CONVERSATION,
  CONVERSATION_ERROR,
  GET_MESSAGES,
  SEND_MESSAGE,
  MESSAGE_ERROR,
  STATE_RESET,
  CANDIDATE_INFO,
  ADD_STATUS,
  SEND_TEMPLATE_SUCCESS,
  SEND_TEMPLATE_ERROR,
  MARK_AS_READ,
  MARK_AS_UNREAD,
  GET_CREDITS,
} from '../types';
import { API_URL } from '../../Constants';

const ConversationState = (props) => {
  const context2 = useContext(AuthContext);

  const { email } = context2;

  const initialState = {
    error: null,
    conversations: [],
    messages: [],
    success: false,
    message_success: false,
    mark_read_success: false,
    conversation_success: false,
    candidate: null,
    filter: [''],
    subFilter: 'All',
    credits: null,
  };

  const [state, dispatch] = useReducer(ConversationReducer, initialState);

  const stateReset = async (resetState) => {
    dispatch({
      type: STATE_RESET,
      payload: resetState,
    });
  };

  const getConversation = async (query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const obj = { email: localStorage.email ? localStorage.email : email };

    const param = query === undefined ? state.subFilter : query;

    try {
      const res = await axios.get(
        API_URL + `/candidates/initiated?email=${obj.email}&filter=${param}`,
        config
      );

      if (res) {
        dispatch({
          type: GET_CONVERSATION,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: CONVERSATION_ERROR,
        payload: err.response.data,
      });
    }
  };

  const getCredits = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const obj = { email: localStorage.email ? localStorage.email : email };

    try {
      const res = await axios.get(
        API_URL + `/candidates/remain-credits?email=${obj.email}`,
        config
      );

      if (res) {
        dispatch({
          type: GET_CREDITS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      console.log(err);
      // dispatch({
      //   type: CONVERSATION_ERROR,
      //   payload: err.response.data,
      // });
    }
  };

  const getCandidate = async (id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.get(API_URL + `/candidates/info/${id}`, config);

      if (res) {
        dispatch({
          type: CANDIDATE_INFO,
          payload: res.data.info,
        });
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const getMessages = async (id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const obj = { email: localStorage.email ? localStorage.email : email };

      const res = await axios.get(
        API_URL + `/messages/all/${id}?email=${obj.email}`,
        config
      );

      if (res) {
        dispatch({
          type: GET_MESSAGES,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: MESSAGE_ERROR,
        payload: err.response.data,
      });
    }
  };

  const MarkRead = async (id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const obj = { email: localStorage.email ? localStorage.email : email };

    try {
      const res2 = await axios.put(
        API_URL + `/messages/markasread/${id}?email=${obj.email}`,
        config
      );

      if (res2) {
        getConversation();
        dispatch({
          type: MARK_AS_READ,
        });
      }
    } catch (err) {
      dispatch({
        type: MESSAGE_ERROR,
        payload: err.response.data,
      });
    }
  };

  const sendMessage = async (receiver) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      const res = await axios.post(
        API_URL + '/messages/text',
        receiver,
        config
      );

      // const res2 = await axios.put(
      //   API_URL + `/messages/markasread/${receiver.id}?email=${obj.email}`,
      //   config
      // );

      if (res) {
        // getConversation();
        dispatch({
          type: SEND_MESSAGE,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: MESSAGE_ERROR,
        payload: err.response.data,
      });
    }
  };

  const sendMedia = async (receiver) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const obj = { email: localStorage.email ? localStorage.email : email };

    try {
      const res = await axios.post(
        API_URL + '/messages/media',
        receiver,
        config
      );

      // const res2 = await axios.put(
      //   API_URL + `/messages/markasread/${receiver.id}?email=${obj.email}`,
      //   config
      // );

      if (res) {
        // getConversation();
        dispatch({
          type: SEND_MESSAGE,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: MESSAGE_ERROR,
        payload: err.response.data,
      });
    }
  };

  const sendTemplate = async (receiver) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const obj = { email: localStorage.email ? localStorage.email : email };

    try {
      const res = await axios.post(
        API_URL + '/messages/templates',
        receiver,
        config
      );

      // const res2 = await axios.put(
      //   API_URL + `/messages/markasread/${receiver.id}?email=${obj.email}`,
      //   config
      // );

      if (res) {
        // getConversation();
        dispatch({
          type: SEND_TEMPLATE_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: SEND_TEMPLATE_ERROR,
        payload: err.response.data,
      });
    }
  };

  const postAssign = async (object) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      await axios.post(API_URL + '/candidates/assign', object, config);

      getConversation();
    } catch (err) {
      dispatch({
        type: MESSAGE_ERROR,
        payload: err.response.data,
      });
    }
  };

  const addStatus = async (id, status) => {
    try {
      var data = {
        status: status,
      };

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      const res = await axios.post(
        API_URL + `/candidates/status/${id}`,
        data,
        config
      );
      if (res) {
        dispatch({
          type: ADD_STATUS,
          payload: status,
        });
      }
    } catch (err) {
      dispatch({
        type: MESSAGE_ERROR,
        payload: err.response.data,
      });
    }
  };

  return (
    <ConversationContext.Provider
      value={{
        conversations: state.conversations,
        messages: state.messages,
        success: state.success,
        error: state.error,
        candidate: state.candidate,
        filter: state.filter,
        subFilter: state.subFilter,
        message_success: state.message_success,
        mark_read_success: state.mark_read_success,
        conversation_success: state.conversation_success,
        credits: state.credits,
        getConversation,
        getMessages,
        stateReset,
        sendMessage,
        getCandidate,
        addStatus,
        postAssign,
        sendTemplate,
        sendMedia,
        MarkRead,
        getCredits,
      }}>
      {props.children}
    </ConversationContext.Provider>
  );
};

export default ConversationState;
