import React, { useState, useContext, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
// import JobContext from '../../Context/job/JobContext';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TemplateContext from '../../Context/template/TemplateContext';
import CreateTemplates from './CreateTemplates';
// import PlayCircleIcon from '@mui/icons-material/PlayCircle';
// import IconButton from '@mui/material/IconButton';
import EditTemplates from './EditTemplates';
import { message, Alert, Space, Button as Butto } from 'antd';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const CreateNew = ({ type }) => {
  const [open, setOpen] = useState(false);

  //   const { id } = props;
  const context2 = useContext(TemplateContext);

  const { create_template_success, update_template_success } = context2;

  //   const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);
  const [editing, setEditing] = useState(false);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (create_template_success || update_template_success) {
      setOpen(false);
    }
    // eslint-disable-next-line
  }, [create_template_success, update_template_success]);

  //   const handleTab = (event, newValue) => {
  //     event.preventDefault();
  //     // setValue(newValue);
  //   };

  const handleTab2 = (event, newValue) => {
    // event.preventDefault();
    setValue2(newValue);
    setEditing(false);
  };

  const parentEditing = () => {
    setEditing(true);
  };

  const parentNotEditing = () => {
    setEditing(false);
    setOpen(false);
  };

  return (
    <>
      {type && (
        <>
          <a
            className='whatsapp-builder-button'
            onClick={() => {
              setOpen(true);
            }}>
            <i>+ Add {type}...</i>
          </a>

          {alert ? (
            <Space
              direction='vertical'
              style={{
                width: '100%',
                marginBottom: '10px',
              }}>
              <Alert
                closable
                onClose={() => setAlert(false)}
                message={
                  <span>Alert !! All unsaved changes will be lost.</span>
                }
                showIcon
                type='warning'
                action={
                  <Space direction='vertical'>
                    <Butto
                      size='small'
                      style={{ width: '100%' }}
                      type='primary'
                      danger
                      onClick={() => setAlert(false)}>
                      Continue Editing
                    </Butto>
                    <Butto
                      size='small'
                      style={{ width: '100%' }}
                      onClick={() => setOpen(false)}>
                      Close Anyway
                    </Butto>
                  </Space>
                }
              />
            </Space>
          ) : null}
          {open && (
            <CreateTemplates
              type={type}
              parentEditing={parentEditing}
              parentNotEditing={parentNotEditing}
            />
          )}
        </>
      )}
    </>
  );
};

export default CreateNew;
