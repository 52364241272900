import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import ConversationContext from '../../../Context/conversation/ConversationContext';
import { database } from '../../../config/fire';
import LoadingButton from '@mui/lab/LoadingButton';
import { getStorage, uploadBytes, ref as sref } from 'firebase/storage';
import ImageIcon from '@mui/icons-material/Image';

const SendImage = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(true);

  const { phone, id, closeIt } = props;
  const context = useContext(ConversationContext);

  const { sendMedia } = context;

  const handleImage = async (event) => {
    setLoading(true);
    const file = event.target.files[0];

    const storage = getStorage();

    await uploadBytes(sref(storage, 'extras/' + file.name), file).then(
      (snapshot) => {
        setValue(
          'https://firebasestorage.googleapis.com/v0/b/ta-coaching.appspot.com/o/' +
            encodeURIComponent(snapshot.metadata.fullPath) +
            '?alt=media'
        );
      }
    );

    setLoading(false);
  };

  const handleSubmit = async () => {
    if (value) {
      let obj = {
        phone: phone,
        id: id,
        link: value,
        type: 'image',
        email: localStorage.email,
      };
      sendMedia(obj);
      setValue();
      setOpen(false);
      closeIt();
    }
  };

  return (
    <>
      <Button
        variant='standard'
        style={{ textTransform: 'capitalize' }}
        onClick={() => {
          setOpen(true);
        }}>
        <ImageIcon fontSize='medium' />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Choose an Image</DialogTitle>
        <DialogContent>
          {phone ? (
            <input
              onChange={handleImage}
              type='file'
              accept='image/png, image/jpeg'
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            variant='standard'
            style={{ textTransform: 'capitalize' }}
            onClick={() => setOpen(false)}>
            Close
          </Button>
          <LoadingButton
            variant='contained'
            loading={loading}
            style={{ textTransform: 'capitalize' }}
            onClick={handleSubmit}
            autoFocus>
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendImage;
