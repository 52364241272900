import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/auth/login';
import SignUp from './pages/auth/signUp';
import Home from './pages/Home';
import AuthState from './Context/auth/AuthState';
import JobState from './Context/job/JobState';
import TemplateState from './Context/template/TemplateState';
import ConversationState from './Context/conversation/ConversationState';
import SetAuthToken from './utils/SetAuthToken';
import jwt_decode from 'jwt-decode';
import Main from './pages/job/Main';
import Usage from './pages/Usage';
import Admin from './pages/admin/Admin';

function parseJwt() {
  const token = localStorage.getItem('token');
  if (jwt_decode(token).exp < Date.now() / 1000) {
    localStorage.clear();
  }
}

if (localStorage.token) {
  parseJwt();
  SetAuthToken(localStorage.token);
}

const App = () => {
  return (
    <AuthState>
      <ConversationState>
        <JobState>
          <TemplateState>
            <div className='App'>
              <Router>
                <Routes>
                  <Route exact path='/admin' element={<Admin />} />
                  <Route exact path='/login' element={<Login />} />
                  <Route exact path='/signup' element={<SignUp />} />
                  <Route exact path='/' element={<Home />} />
                  <Route exact path='/jobs' element={<Main />} />
                  <Route exact path='/jobs/:id' element={<Main />} />
                  <Route exact path='/chat/:id' element={<Home />} />
                  <Route exact path='/chats/:query' element={<Home />} />
                  <Route exact path='/usage' element={<Usage />} />
                </Routes>
              </Router>
            </div>
          </TemplateState>
        </JobState>
      </ConversationState>
    </AuthState>
  );
};

export default App;
