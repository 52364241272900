import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ConversationContext from '../../../Context/conversation/ConversationContext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { message } from 'antd';

const OtherTemplates = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('customized_message');
  const [text, setText] = useState('');

  const { phone, id, closeIt } = props;
  const context = useContext(ConversationContext);

  const { sendTemplate } = context;

  const handleSubmit = async () => {
    if (text === '') {
      return message.warning('Please add a message');
    }

    let obj = {
      id: id,
      phone: phone,
      template_name: 'customized_message',
      text: text,
      language_code: 'en',
      email: localStorage.email,
      component: [
        {
          type: 'body',
          parameters: [
            {
              type: 'text',
              text: text,
            },
          ],
        },
      ],
    };

    setText('');
    sendTemplate(obj);

    setValue('customized_message');
    setOpen(false);
    closeIt();
  };

  return (
    <>
      <Button
        variant='standard'
        style={{ textTransform: 'capitalize' }}
        onClick={() => {
          setOpen(true);
        }}>
        <ManageSearchIcon fontSize='medium' />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Choose a Template Message</DialogTitle>
        <DialogContent>
          {phone ? (
            <RadioGroup
              value={value}
              onChange={(e) => setValue(e.target.value)}>
              <FormControlLabel
                value='customized_message'
                control={<Radio />}
                label='Custom Messaage Template'
              />
              <br />
              {value === 'customized_message' ? (
                <TextField
                  label='Enter Your Message'
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              ) : null}
              <br />
            </RadioGroup>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            variant='standard'
            style={{ textTransform: 'capitalize' }}
            onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button
            variant='contained'
            style={{ textTransform: 'capitalize' }}
            onClick={handleSubmit}
            autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OtherTemplates;
