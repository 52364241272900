import React, { useState, useEffect, useContext, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import SendRounded from '@mui/icons-material/SendRounded';
import AddIcon from '@mui/icons-material/Add';
import ConversationContext from '../../Context/conversation/ConversationContext';
import { useParams } from 'react-router-dom';
import EmojiPicker from 'emoji-picker-react';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import './chat.css';
import DisplayMedia from './DisplayMedia';
import FollowUp from './OtherTypes/FollowUp';
import LateReply from './OtherTypes/LateReply';
import AddStatus from './AddStatus';
import EditStatus from './EditStatus';
import SendImage from './OtherTypes/SendImage';
import SendDocument from './OtherTypes/SendDocument';
import Tooltip from '@mui/material/Tooltip';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import DisplayMediaLink from './DisplayMediaLink';
import OtherTemplates from './OtherTypes/OtherTemplates';
import InfoIcon from '@mui/icons-material/Info';
import { message } from 'antd';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

const ChatBox = () => {
  let { id } = useParams();

  const messagesEndRef = useRef(null);

  const context = useContext(ConversationContext);

  const { candidate, message_success, messages, getMessages } = context;
  const { error, stateReset, getCandidate, sendMessage, MarkRead } = context;

  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [text, setText] = useState('');
  const [emoji, setEmoji] = useState(false);
  const [latest, setLatest] = useState(false);
  const [activeChat, setActiveChat] = useState(false);
  const handleExpanded = () => setExpanded(!expanded);

  useEffect(() => {
    async function initial() {
      setLoading(true);
      await getCandidate(id);
      await getMessages(id);
    }
    if (id) {
      setLatest(false);

      initial();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (error) {
      setLoading(false);
      message.error(error);
      stateReset({ error: null });
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (message_success) {
      setLoading(false);
      stateReset({ message_success: false });
      scrollToBottom();

      if (messages && window.location.href.includes('chat/')) MarkRead(id);
    }
    // eslint-disable-next-line
  }, [message_success]);

  useEffect(() => {
    if (messages && window.location.href.includes('chat/')) {
      if (messages.read || messages.unread) {
        const alll = [...messages.read, ...messages.unread];
        const tim = (Date.now() / 1000 - alll.slice(-1)[0].timestamp) / 3600;
        setLatest(tim >= 24);

        const tim2 = (Date.now() / 1000 - alll[0].timestamp) / 3600;
        if (
          localStorage.plan_type === 'Free' ||
          localStorage.plan_type === 'FreePro'
        )
          setActiveChat(tim2 <= 24);
        else setActiveChat(tim2 <= 72);
        scrollToBottom();
      }
    }
    // eslint-disable-next-line
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

    const el = document.getElementById('chat');

    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  };

  const handleSendMessage = () => {
    setEmoji(false);

    let send = {
      phone: candidate.phone,
      id: id,
      text_msg: text,
      email: localStorage.email,
    };
    sendMessage(send);
    setText('');
  };

  const handleEmoji = (val) => {
    setText(text + val.emoji);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleSendMessage();
      event.preventDefault();
    }
  };

  if (!window.location.href.includes('chat/'))
    return (
      // <Grid item xs={false} style={{ width: '50%' }} md={7} className='image' />
      <></>
    );

  const actions = [
    {
      icon: (
        <FollowUp
          closeIt={() => setExpanded(false)}
          phone={candidate?.phone}
          id={candidate?._id}
        />
      ),
      name: 'Follow Up',
    },
    {
      icon: (
        <LateReply
          closeIt={() => setExpanded(false)}
          phone={candidate?.phone}
          id={candidate?._id}
        />
      ),
      name: 'Late Reply',
    },
    {
      icon: (
        <OtherTemplates
          closeIt={() => setExpanded(false)}
          phone={candidate?.phone}
          id={candidate?._id}
        />
      ),
      name: 'Other Templates',
    },
    {
      icon: (
        <SendImage
          closeIt={() => setExpanded(false)}
          phone={candidate?.phone}
          id={candidate?._id}
        />
      ),
      name: 'Send Image',
    },
    {
      icon: (
        <SendDocument
          closeIt={() => setExpanded(false)}
          phone={candidate?.phone}
          id={candidate?._id}
        />
      ),
      name: 'Send Document',
    },
  ];

  return (
    <>
      <Grid
        id='chatbox'
        container
        spacing={0}
        style={{
          backgroundColor: '#edfaf8',
          height: '99vh',
          marginTop: '-10px',
          width: `calc(100% - 361px)`,
          // width: '100%',
        }}>
        <Grid item xs={12}>
          <div className='candidatesItems'>
            <Grid container spacing={0}>
              <Grid item>
                <Image sx={{ bgcolor: '#fff' }} alt={candidate?.name}>
                  {candidate?.name.charAt(0)}
                </Image>
              </Grid>
              <Grid item xs={5} className='textContainer'>
                <p className='candidateName'>{candidate?.name}</p>
                <p className='companyName'>
                  {candidate?.phone?.toString().substring(2)}
                </p>
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                {candidate?.status ? (
                  <>
                    <span>Status: {candidate?.status}</span>
                    <EditStatus id={candidate?._id} stat={candidate?.status} />
                  </>
                ) : (
                  <AddStatus id={candidate?._id} />
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          onClick={() => {
            setEmoji(false);
            setExpanded(false);
          }}
          item
          xs={12}
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <div
              style={{
                overflowY: 'auto',
                flexGrow: '2',
                height: '75vh',
              }}
              id='chat'>
              <Grid container spacing={0}>
                {messages &&
                  messages?.read?.map((message) => {
                    return (
                      <Grid item xs={12} key={message._id}>
                        {message.sender === 'user' ? (
                          <Paper elevation={0} className='message'>
                            <span style={{ width: '100%' }}>
                              {message.type === 'text' ||
                              message.type === 'template' ? (
                                message.body.body
                              ) : message.type === 'reaction' ? (
                                message.body.emoji
                              ) : message.type === 'button' ? (
                                message.body.text
                              ) : (
                                <DisplayMedia mediaId={message.body.id} />
                              )}
                              <span className='mess-timestamp'>
                                {new Date(
                                  message?.timestamp * 1000
                                ).toLocaleTimeString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                })}
                              </span>
                            </span>
                          </Paper>
                        ) : (
                          <Paper elevation={0} className='messageRight'>
                            <span style={{ width: '100%' }}>
                              {message.type === 'text' ||
                              message.type === 'template' ? (
                                message.body.body
                              ) : message.type === 'button' ? (
                                message.body.text
                              ) : (
                                <DisplayMediaLink url={message.body.link} />
                              )}
                              <span className='mess-timestamp'>
                                {new Date(
                                  message?.timestamp * 1000
                                ).toLocaleTimeString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                })}
                              </span>
                            </span>
                          </Paper>
                        )}
                      </Grid>
                    );
                  })}
              </Grid>
              {messages && messages?.unread?.length > 0 ? (
                <Grid container spacing={0}>
                  <div
                    style={{
                      width: '96%',
                      margin: 'auto',
                      height: '12px',
                      borderBottom: '1px solid lightgray',
                      textAlign: 'center',
                      marginBottom: '10px',
                    }}>
                    <span
                      style={{
                        fontSize: '15px',
                        color: 'gray',
                        borderRadius: '16px',
                        backgroundColor: 'lightgrey',
                        padding: '0 10px',
                      }}>
                      Unread
                    </span>
                  </div>
                  {messages &&
                    messages?.unread?.map((message) => {
                      return (
                        <Grid item xs={12} key={message._id}>
                          {message.sender === 'user' ? (
                            <Paper elevation={0} className='message'>
                              <span style={{ width: '100%' }}>
                                {message.type === 'text' ||
                                message.type === 'template' ? (
                                  message.body.body
                                ) : message.type === 'button' ? (
                                  message.body.text
                                ) : message.type === 'reaction' ? (
                                  message.body.emoji
                                ) : (
                                  <DisplayMedia mediaId={message.body.id} />
                                )}
                                <span className='mess-timestamp'>
                                  {new Date(
                                    message?.timestamp * 1000
                                  ).toLocaleTimeString('en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true,
                                  })}
                                </span>
                              </span>
                            </Paper>
                          ) : (
                            <Paper elevation={0} className='messageRight'>
                              <span style={{ width: '100%' }}>
                                {message.type === 'text' ||
                                message.type === 'template' ? (
                                  message.body.body
                                ) : message.type === 'button' ? (
                                  message.body.text
                                ) : (
                                  <DisplayMediaLink url={message.body.link} />
                                )}
                                <span className='mess-timestamp'>
                                  {new Date(
                                    message?.timestamp * 1000
                                  ).toLocaleTimeString('en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true,
                                  })}
                                </span>
                              </span>
                            </Paper>
                          )}
                        </Grid>
                      );
                    })}
                </Grid>
              ) : null}
              {messages && messages?.read && messages?.unread && !loading ? (
                <div ref={messagesEndRef} />
              ) : null}
            </div>
          )}
        </Grid>
        {/* {expanded && (
          <Grid item xs={12} className='optionNav'>
            <Box p={2} className='optionBox'>
              <h4>Choose Action</h4>
              <Grid
                container
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}>
                <Grid item>
                  <FollowUp
                    closeIt={() => setExpanded(false)}
                    phone={candidate?.phone}
                    id={candidate?._id}
                  />
                </Grid>
                <Grid item>
                  <LateReply
                    closeIt={() => setExpanded(false)}
                    phone={candidate?.phone}
                    id={candidate?._id}
                  />
                </Grid>
                <Grid item>
                  <OtherTemplates
                    closeIt={() => setExpanded(false)}
                    phone={candidate?.phone}
                    id={candidate?._id}
                  />
                </Grid>
                <Grid item>
                  <SendImage
                    closeIt={() => setExpanded(false)}
                    phone={candidate?.phone}
                    id={candidate?._id}
                  />
                </Grid>
                <Grid item>
                  <SendDocument
                    closeIt={() => setExpanded(false)}
                    phone={candidate?.phone}
                    id={candidate?._id}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )} */}
        {emoji ? (
          <EmojiPicker
            lazyLoadEmojis={true}
            emojiStyle='google'
            onEmojiClick={handleEmoji}
            theme='dark'
          />
        ) : null}
        <Grid
          item
          xs={12}
          style={{
            padding: '10px',
            marginTop: 'auto',
          }}>
          {latest && !expanded && activeChat ? (
            <div
              style={{
                display: 'flex',
                fontSize: '13px',
                marginBottom: '-5px',
              }}>
              <InfoIcon fontSize='small' color='primary' />
              <i style={{ marginLeft: '3px' }}>
                Please send a template message because it's been more than 24
                hours and wait for candidate reply to start conversation
              </i>
            </div>
          ) : null}

          {activeChat ? (
            <form onSubmit={handleSendMessage}>
              <Paper className='inputRoot'>
                {/* <Tooltip title='Mark as Unread'>
                <IconButton>
                  <MarkEmailUnreadIcon />
                </IconButton>
              </Tooltip>
              <Divider className='divider' orientation='vertical' /> */}
                <IconButton onClick={() => setEmoji(!emoji)}>
                  <AddReactionIcon />
                </IconButton>
                <Divider className='divider' orientation='vertical' />
                <TextField
                  required
                  disabled={latest}
                  multiline={true}
                  maxRows={3}
                  className='input'
                  value={text}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setText(e.target.value)}
                  placeholder='Type your message here...'
                />
                <IconButton
                  type='submit'
                  className='iconButton'
                  aria-label='send'>
                  <SendRounded color='primary' />
                </IconButton>
                <Divider className='divider' orientation='vertical' />
                <SpeedDial
                  ariaLabel='Custom'
                  sx={{ position: 'absolute', bottom: 36, right: 7 }}
                  icon={<SpeedDialIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />}
                  onClick={handleExpanded}
                  open={expanded}>
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      // tooltipOpen
                      onClick={handleExpanded}
                    />
                  ))}
                </SpeedDial>
                <IconButton>
                  <AddIcon style={{ color: '#fff' }} />
                </IconButton>
              </Paper>
            </form>
          ) : (
            <div
              style={{
                display: 'flex',
                fontSize: '13px',
                marginBottom: '-5px',
              }}>
              <InfoIcon fontSize='small' color='primary' />
              <i style={{ marginLeft: '3px' }}>
                Please upgrade your plan to chat further
              </i>
            </div>
          )}
        </Grid>
      </Grid>
      {scrollToBottom()}
    </>
  );
};

const Image = styled(Avatar)`
  margin: 5px 10px 10px 10px;
  height: 35px;
  width: 35px;
  color: #b32800;
  border: 1px solid #b32800;
`;

export default ChatBox;
